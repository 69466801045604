import { Grid, Button, TextField, Typography, IconButton, Autocomplete } from "@mui/material";
import { useState, useEffect, useContext, useRef, useCallback } from "react";
import ErrorBox from "../components/Common/ErrorBox";
import { UserContext } from "../context/UserContext";
import { axiosInstance } from "../utils/utils";
import TopBar from "../components/Layout/TopBar";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { useNavigate } from "react-router-dom";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DeleteDialog from "../components/Common/DeleteDialog";
import { useParams } from "react-router-dom";
import * as pdfjsLib from "pdfjs-dist/webpack";

const EditProject = props => {
    const [disableSubmitButton, setDisableSubmitButton] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [image, setImage] = useState();
    const [details, setDetails] = useState({ name: "", description: "", person: "", email: "", phone: "" });
    const [blueprint, setBlueprint] = useState();
    const [openDelete, setOpenDelete] = useState(false);
    const [userContext, setUserContext] = useContext(UserContext);
    const [imageModified, setImageModified] = useState(false);
    const [blueprintModified, setBlueprintModified] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [, setModified] = useState(false);
    const params = useParams();
    const topRef = useRef(null);
    const autocompleteParams = { fullWidth: true, freeSolo: true, autoSelect: false, autoComplete: true, autoHighlight: false, blurOnSelect: true };
    const headingParams = { variant: "body1", sx: { fontWeight: "bold", color: "#495464", mr: 1 } };

    const handleDelete = () => {
        const url = process.env.REACT_APP_API_URL + "/projects/" + params._id;
        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

        axiosInstance.delete(url, config)
            .then(res => {
                navigate("/main", { replace: true, state: { snackbarMessage: "Project deleted" } });
            })
            .catch(err => {
                setOpenDelete(false);
                setErrorMessage("Unable to delete project");
                setShowErrorMessage(true);
            });
    };

    const getImage = useCallback(async image => {
        try {
            const url = process.env.REACT_APP_API_URL + "/images/" + image;
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext, responseType: "arraybuffer" };
            const fetchRes = await axiosInstance.get(url, config);
            const blob = new Blob([fetchRes.data], { type: fetchRes.headers.getContentType() });
            return URL.createObjectURL(blob);
        } catch (err) {
            // Do nothing
        }
    }, [setUserContext, userContext]);

    useEffect(() => {
        const fetchEntry = async () => {
            let url = process.env.REACT_APP_API_URL + "/projects/" + params._id;
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            try {
                const res = await axiosInstance.get(url, config);
                setDetails(details => ({ ...details, ...res.data }));

                if (res.data.image) {
                    setImage(await getImage(res.data.image));
                }

                if (res.data.blueprint) {
                    setBlueprint(await getImage(res.data.blueprint));
                }
            } catch (err) {
                // Do nothing
            }
        };

        fetchEntry();
    }, [params._id, userContext, setUserContext, getImage]);

    const handleSubmit = event => {
        event.preventDefault();
        setDisableSubmitButton(true);

        if (!imageModified) {
            delete details.image;
        }

        if (!blueprintModified) {
            delete details.blueprint;
        }

        const url = process.env.REACT_APP_API_URL + "/projects/" + params._id;
        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };
        const payload = details;

        axiosInstance.postForm(url, payload, config)
            .then(res => {
                navigate("/projects/" + details._id, { replace: true, state: { snackbarMessage: "Project modified" } });
            })
            .catch(err => {
                setErrorMessage(err.response.data);
                setShowErrorMessage(true);
                setDisableSubmitButton(false);
            });
    };

    useEffect(() => setIsLoading(!details._id), [details]);

    const handleChange = (name, value) => {
        setDetails(pairs => ({ ...pairs, [name]: value }));
        setModified(true);
    };

    const handleImageChange = (event, value) => {
        handleChange("image", event.target.files[0]);
        setImage(URL.createObjectURL(event.target.files[0]));
        setImageModified(true);
        setModified(true);
    };

    const handleRemoveImage = () => {
        handleChange("image", "");
        setImage();
        setImageModified(true);
        setModified(true);
    };

    useEffect(() => {
        const fieldsNotEmpty = () => {
            return details.name;
        };

        setDisableSubmitButton(!fieldsNotEmpty());
    }, [details]);

    const handleBlueprintChange = async (event, value) => {
        if (event.target.files[0].type === "application/pdf") {
            const file = event.target.files[0];
            const url = URL.createObjectURL(file);
            const loadingTask = await pdfjsLib.getDocument({ url: url });
            const pdf = await loadingTask.promise;
            const page = await pdf.getPage(1);

            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");
            const viewport = page.getViewport({ scale: 2 });
            canvas.width = viewport.width;
            canvas.height = viewport.height;
            const renderContext = {
                canvasContext: context,
                viewport: viewport,
            };
            await page.render(renderContext).promise;

            const imgUrl = canvas.toDataURL("image/jpeg");
            const img = await fetch(imgUrl);
            const imgBlob = await img.blob();
            const imgFile = new File([imgBlob], "image", { type: imgBlob.type });

            handleChange("blueprint", imgFile);
            setBlueprint(imgUrl);
            setBlueprintModified(true);
            setModified(true);
        } else {
            handleChange("blueprint", event.target.files[0]);
            setBlueprint(URL.createObjectURL(event.target.files[0]));
            setBlueprintModified(true);
            setModified(true);
        }
    };

    const handleRemoveBlueprint = () => {
        handleChange("blueprint", "");
        setBlueprint();
        setBlueprintModified(true);
        setModified(true);
    };

    const handlePaste = async (field) => {
        try {
            const items = await navigator.clipboard.read();
            let blob = null;
            let url = "";
            let imgBlob = null;

            for (let item of items) {
                if (item.types.includes("image/png")) {
                    imgBlob = await item.getType("image/png");
                    break;
                } else if (item.types.includes("text/html")) {
                    blob = await item.getType("text/html");
                    url = await blob.text();
                    break;
                } else if (item.types.includes("text/plain")) {
                    blob = await item.getType("text/plain");
                    url = await blob.text();
                    break;
                } else if (item.types.includes("text/uri-list")) {
                    blob = await item.getType("text/uri-list");
                    url = await blob.text();
                    break;
                }
            }

            if (url) {
                const apiUrl = process.env.REACT_APP_API_URL + "/fetchimage?url=" + encodeURIComponent(url);
                const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext, responseType: "arraybuffer" };

                try {
                    const res = await axiosInstance(apiUrl, config);
                    const blob = new Blob([res.data], { type: res.headers.getContentType() });
                    const imgFile = new File([blob], "image", { type: blob.type });

                    handleChange(field, imgFile);

                    if (field === "image") {
                        setImage(URL.createObjectURL(imgFile));
                        setImageModified(true);
                    } else if (field === "blueprint") {
                        setBlueprint(URL.createObjectURL(imgFile));
                        setBlueprintModified(true);
                    }

                    setModified(true);
                } catch (err) {
                    // Do nothing
                }
            } else if (imgBlob) {
                const imgFile = new File([imgBlob], "image", { type: imgBlob.type });

                handleChange(field, imgFile);

                if (field === "image") {
                    setImage(URL.createObjectURL(imgFile));
                    setImageModified(true);
                } else if (field === "blueprint") {
                    setBlueprint(URL.createObjectURL(imgFile));
                    setBlueprintModified(true);
                }

                setModified(true);
            }
        } catch (err) {
            // Do nothing
        };
    };

    useEffect(() => {
        if (!topRef.current) return;
        topRef.current.scrollIntoView();
    }, [topRef]);

    useEffect(() => setIsLoading(details._id === ""), [details]);

    return (!isLoading &&
        <Grid container direction="column" sx={{ maxWidth: "1000px" }} ref={topRef}>
            <Grid container item sx={{ justifyContent: "center", paddingBottom: "25px" }}>
                <TopBar title="Edit Project" enableBack modified={false} backRoute={-1} />
            </Grid>
            <Grid container item sx={{ justifyContent: "center", paddingBottom: "35px" }}>
                <Typography color="black">What changes do you want to make?</Typography>
            </Grid>
            <Grid container item justifyContent="center">
                <form onSubmit={handleSubmit} style={{ width: "100%", maxWidth: "650px" }}>
                    <Grid container item spacing={4} sx={{ mb: 1.5 }}>
                        <Grid container item spacing={2.5} alignItems="center">
                            <Grid container item alignItems="center">
                                <Typography {...headingParams}>Vessel</Typography>
                            </Grid>
                        </Grid>
                        <Grid container item>
                            <Autocomplete
                                {...autocompleteParams}
                                options={[]}
                                renderInput={params => (<TextField {...params} label="Vessel name" value={details.name} inputProps={{ ...params.inputProps, autoCapitalize: "words" }} InputLabelProps={{ shrink: true, required: true }} helperText="Example: Double Down" />)}
                                onInputChange={(event, value) => handleChange("name", value)}
                                inputValue={details.name}
                            />
                        </Grid>
                        <Grid container item>
                            <Autocomplete
                                {...autocompleteParams}
                                options={[]}
                                renderInput={params => (<TextField {...params} label="Vessel length and type" value={details.description} inputProps={{ ...params.inputProps, autoCapitalize: "words" }} InputLabelProps={{ shrink: true }} helperText="Example: 108' Broward" />)}
                                onInputChange={(event, value) => handleChange("description", value)}
                                inputValue={details.description}
                            />
                        </Grid>
                        <Grid container item spacing={2.5} alignItems="center">
                            <Grid container item alignItems="center">
                                <Typography {...headingParams}>Contact</Typography>
                            </Grid>
                        </Grid>
                        <Grid container item>
                            <Autocomplete
                                {...autocompleteParams}
                                options={[]}
                                renderInput={params => (<TextField {...params} label="Name" value={details.person} inputProps={{ ...params.inputProps, autoCapitalize: "words" }} InputLabelProps={{ shrink: true }} />)}
                                onInputChange={(event, value) => handleChange("person", value)}
                                inputValue={details.person}
                            />
                        </Grid>
                        <Grid container item>
                            <Autocomplete
                                {...autocompleteParams}
                                options={[]}
                                renderInput={params => (<TextField {...params} label="Email" type="email" value={details.email} inputProps={{ ...params.inputProps, autoCapitalize: "none" }} InputLabelProps={{ shrink: true }} />)}
                                onInputChange={(event, value) => handleChange("email", value)}
                                inputValue={details.email}
                            />
                        </Grid>
                        <Grid container item>
                            <Autocomplete
                                {...autocompleteParams}
                                options={[]}
                                renderInput={params => (<TextField {...params} label="Phone" type="tel" value={details.phone} inputProps={{ ...params.inputProps, autoCapitalize: "none" }} InputLabelProps={{ shrink: true }} />)}
                                onInputChange={(event, value) => handleChange("phone", value)}
                                inputValue={details.phone}
                            />
                        </Grid>
                        <Grid container item spacing={2.5} alignItems="center">
                            <Grid container item alignItems="center">
                                <Typography {...headingParams}>Photo</Typography>
                            </Grid>
                        </Grid>
                        <Grid container item direction="column" spacing={2.5}>
                            <Grid container item>
                                <div style={{ width: "100%", borderRadius: "5px" }}>
                                    {image ?
                                        <img src={image} alt="" width="100%" height="100%" onError={handleRemoveImage} />
                                        :
                                        <Grid container item sx={{ alignContent: "center", height: "200px", borderRadius: "5px", border: "2px dotted #495464" }}>
                                            <Grid container item justifyContent="center">
                                                <Typography sx={{ color: "rgba(32, 42, 68, 0.6)", textAlign: "center", fontSize: "14px" }}>Select a method to set vessel image</Typography>
                                            </Grid>
                                            <Grid container item spacing={3.5} justifyContent="center" sx={{ mt: "10px" }}>
                                                <Grid item>
                                                    <label>
                                                        <input hidden type="file" accept="image/*" onChange={handleImageChange} onClick={event => event.target.value = null} />
                                                        <IconButton component="span" sx={{ color: "#495464", "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.1)" } }}>
                                                            <CollectionsOutlinedIcon sx={{ width: 35, height: 35 }} />
                                                        </IconButton>
                                                    </label>
                                                </Grid>
                                                <Grid item>
                                                    <IconButton onClick={() => handlePaste("image")} sx={{ color: "#495464", "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.1)" } }}>
                                                        <ContentPasteIcon sx={{ width: 35, height: 35 }} />
                                                    </IconButton>
                                                </Grid>
                                                {details.name &&
                                                    <Grid item>
                                                        <IconButton
                                                            onClick={() => window.open("https://www.google.com/search?tbm=isch&q=" + details.name.toLowerCase() + " yacht", "_blank")}
                                                            sx={{ color: "#495464", "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.1)" } }}
                                                        >
                                                            <SearchIcon sx={{ width: 35, height: 35 }} />
                                                        </IconButton>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </Grid>
                                    }
                                </div>
                            </Grid>
                            <Grid container item spacing={2.5} alignItems="center">
                                {image &&
                                    <Grid item>
                                        <Button
                                            onClick={handleRemoveImage}
                                            variant="outlined"
                                            sx={{ height: "45px", width: "150px" }}
                                            component="span"
                                        >
                                            <span style={{ marginLeft: "-4px", marginRight: "8px", display: "inherit" }}><ClearIcon sx={{ fontSize: 20 }} /></span>
                                            Clear
                                        </Button>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                        <Grid container item spacing={2.5} alignItems="center">
                            <Grid container item alignItems="center">
                                <Typography {...headingParams}>General Arrangement</Typography>
                            </Grid>
                        </Grid>
                        <Grid container item direction="column" spacing={2.5}>
                            <Grid container item>
                                <div style={{ width: "100%", borderRadius: "5px" }}>
                                    {blueprint ?
                                        <img src={blueprint} alt="" width="100%" height="100%" onError={handleRemoveBlueprint} />
                                        :
                                        <Grid container item sx={{ alignContent: "center", height: "300px", borderRadius: "5px", border: "2px dotted #495464" }}>
                                            <Grid container item justifyContent="center">
                                                <Typography sx={{ color: "rgba(32, 42, 68, 0.6)", textAlign: "center", fontSize: "14px" }}>Select a method to add GA</Typography>
                                            </Grid>
                                            <Grid container item spacing={3.5} justifyContent="center" sx={{ mt: "10px" }}>
                                                <Grid item>
                                                    <label>
                                                        <input hidden type="file" accept="image/*,application/pdf" onChange={handleBlueprintChange} onClick={event => event.target.value = null} />
                                                        <IconButton component="span" sx={{ color: "#495464", "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.1)" } }}>
                                                            <CollectionsOutlinedIcon sx={{ width: 35, height: 35 }} />
                                                        </IconButton>
                                                    </label>
                                                </Grid>
                                                <Grid item>
                                                    <IconButton onClick={() => handlePaste("blueprint")} sx={{ color: "#495464", "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.1)" } }}>
                                                        <ContentPasteIcon sx={{ width: 35, height: 35 }} />
                                                    </IconButton>
                                                </Grid>
                                                {details.name &&
                                                    <Grid item>
                                                        <IconButton
                                                            onClick={() => window.open("https://www.google.com/search?tbm=isch&q=" + details.name.toLowerCase() + " general arrangement", "_blank")}
                                                            sx={{ color: "#495464", "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.1)" } }}
                                                        >
                                                            <SearchIcon sx={{ width: 35, height: 35 }} />
                                                        </IconButton>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </Grid>
                                    }
                                </div>
                            </Grid>
                            <Grid container item spacing={2.5} alignItems="center">
                                {blueprint &&
                                    <Grid item>
                                        <Button
                                            onClick={handleRemoveBlueprint}
                                            variant="outlined"
                                            sx={{ height: "45px", width: "150px" }}
                                            component="span"
                                        >
                                            <span style={{ marginLeft: "-4px", marginRight: "8px", display: "inherit" }}><ClearIcon sx={{ fontSize: 20 }} /></span>
                                            Clear
                                        </Button>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                        <Grid container item sx={{ mt: 1.5 }}>
                            <Grid container item spacing={2.5} alignItems="center">
                                <Grid container item alignItems="center">
                                    <Typography {...headingParams}>Delete Project</Typography>
                                </Grid>
                            </Grid>
                            <Grid container item sx={{ justifyContent: "space-between", alignItems: "center", mt: 2 }}>
                                <Grid item>
                                    <Typography>Would you like to delete this project?</Typography>
                                </Grid>
                                <Grid item>
                                    <IconButton onClick={() => setOpenDelete(true)}><DeleteOutlineIcon sx={{ fontSize: 30, color: "#495464" }} /></IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <br />
                    <ErrorBox showErrorMessage={showErrorMessage} errorMessage={errorMessage} />
                    <Button disabled={disableSubmitButton} type="submit" sx={{ width: "100%", marginTop: "20px", height: "45px", marginBottom: "35px" }}>Update</Button>
                </form >
            </Grid >
            {openDelete && <DeleteDialog open={openDelete} setOpen={setOpenDelete} handleDelete={handleDelete} type="project" />}
        </Grid >
    );
};

export default EditProject;