import { Grid, Button, Autocomplete, TextField, Typography, Box, IconButton, Paper, Checkbox, FormControlLabel, RadioGroup, Radio, Chip, Modal } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { DataGrid, GridToolbarContainer, useGridApiRef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import TheSnackbar from "../Common/TheSnackbar";
import { UserContext } from "../../context/UserContext";
import { axiosInstance } from "../../utils/utils";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import CloseIcon from "@mui/icons-material/Close";
import ClearIcon from "@mui/icons-material/Clear";
import dayjs from "dayjs";

const ProjectsTable = props => {
    const [userContext, setUserContext] = useContext(UserContext);
    const [rowCount, setRowCount] = useState(0);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage] = useState();
    const [rowCountState, setRowCountState] = useState(rowCount);
    const [data, setData] = useState([]);
    const [sort, setSort] = useState(window.localStorage.getItem("projectsSort") !== null ? window.localStorage.getItem("projectsSort") : "updatedAt:desc");
    const [search, setSearch] = useState(userContext.projectsSearch ? userContext.projectsSearch : "");
    const [myProjects, setMyProjects] = useState(userContext.myProjects !== undefined ? userContext.myProjects : false);
    const [shared, setShared] = useState(userContext.shared !== undefined ? userContext.shared : false);
    const [active, setActive] = useState(userContext.active !== undefined ? userContext.active : false);
    const [completed, setCompleted] = useState(userContext.completed !== undefined ? userContext.completed : false);
    const navigate = useNavigate();
    const [showFilterMenu, setShowFilterMenu] = useState(false);
    const [projectCompleted, setProjectCompleted] = useState(false);
    const [openCompletedModal, setOpenCompletedModal] = useState(false);
    const [selectedId, setSelectedId] = useState();
    const [loading, setLoading] = useState(true);
    const [listening, setListening] = useState(false);
    const [updating, setUpdating] = useState(true);

    const handleClickEntry = (event, _id) => {
        if (event.target.tagName === "INPUT"
            || event.target.tagName === "SPAN"
            || event.target.tagName === "LABEL"
            || event.target.tagName === "A") {

            return;
        }

        navigate("/projects/" + _id);
    };

    const handlePageChange = (newPaginationModel, scrollToTop = true) => {
        props.setPaginationModel(newPaginationModel);
        window.localStorage.setItem("projectsPageSize", newPaginationModel.pageSize);
        setUserContext(userContext => ({ ...userContext, projectsPage: newPaginationModel.page }));

        if (scrollToTop && props.topRef && props.topRef.current) {
            props.topRef.current.scrollIntoView();
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            if (props.paginationModel.pageSize === 0) {
                return;
            }

            let url = process.env.REACT_APP_API_URL + "/projects?page=" + props.paginationModel.page + "&limit=" + props.paginationModel.pageSize;
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            if (sort) {
                url += "&sort=" + sort;
            }

            if (active && !completed) {
                url += "&completed=" + false;
            } else if (completed && !active) {
                url += "&completed=" + true;
            } else if (!completed && !active) {
                url += "&completed=" + false;
            }

            if (shared) {
                url += "&shared=" + true;
            }

            if (search) {
                url += "&search=" + search;
            }

            if (myProjects) {
                url += "&user=" + userContext._id;
            }

            try {
                const res = await axiosInstance.get(url, config);
                const updatedData = [...res.data.projects];

                for (const project of updatedData) {
                    if (project.image) {
                        const url = process.env.REACT_APP_API_URL + "/images/" + process.env.REACT_APP_THUMBNAIL_PREFIX + project.image;
                        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext, responseType: "arraybuffer" };
                        const fetchRes = await axiosInstance.get(url, config);
                        const blob = new Blob([fetchRes.data], { type: fetchRes.headers.getContentType() });
                        project.fetchedImage = URL.createObjectURL(blob);
                    }
                }

                setData(updatedData);
                setRowCount(res.data.total);
                setLoading(false);
                setUpdating(false);
            } catch (err) {
                // Do nothing
            }
        };

        if (loading) {
            fetchData();
        }
    }, [loading, userContext, setUserContext, updating, sort, active, completed, shared, search, props.paginationModel, myProjects]);

    useEffect(() => {
        setRowCountState(prevRowCountState => rowCount !== undefined ? rowCount : prevRowCountState);
    }, [rowCount, setRowCountState]);

    const handleClickCompleted = (value, _id) => {
        setSelectedId(_id);
        setProjectCompleted(value);
        setOpenCompletedModal(true);
    };

    const handleCompletedChange = (event, value, _id) => {
        event.preventDefault();

        const url = process.env.REACT_APP_API_URL + "/projects/" + _id;
        const payload = { completed: value };
        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

        axiosInstance.postForm(url, payload, config)
            .then(res => {
                setUserContext(userContext => ({ ...userContext, refresh: !userContext.refresh }));
            })
            .catch(err => {
                // Do nothing
            });
    };

    const rows = data;

    const columns = [{
        field: "_id",
        headerName: "Project",
        flex: 1,
        renderCell: params => {
            return (
                <Paper
                    key={params.row._id}
                    sx={{
                        backgroundColor: "#ffffff",
                        minHeight: "190px",
                        padding: "12px",
                        display: "flex",
                        justifyContent: "center",
                        boxShadow: "none",
                        width: "100%",
                    }}
                    onClick={event => handleClickEntry(event, params.row._id)}
                >
                    <Grid container direction="column" justifyContent="space-between">
                        <Grid container item direction="row" justifyContent="space-between" wrap="nowrap">
                            <Grid item xs={6} sx={{ marginRight: 1.5 }}>
                                <Typography sx={{ cursor: "pointer", width: "fit-content", fontWeight: "800", fontSize: "17px" }}>
                                    {params.row.name}
                                </Typography>
                                <Typography sx={{ whiteSpace: "pre-line", cursor: "pointer", width: "fit-content", fontSize: "15px" }}>
                                    {params.row.description}
                                </Typography>
                                <Typography sx={{ whiteSpace: "pre-line", cursor: "pointer", width: "fit-content", fontSize: "15px" }}>
                                    {params.row.person}
                                </Typography>
                                {params.row.completed &&
                                    <Chip
                                        label={params.row.completed ? "Completed" : "Active"}
                                        variant="outlined"
                                        onClick={() => handleClickCompleted(params.row.completed ? params.row.completed : false, params.row._id)}
                                        sx={{ mt: 1 }}
                                    />
                                }
                            </Grid>
                            <Grid container item xs={6} justifyContent="flex-end">
                                <Box display="flex">
                                    <div style={{ width: "100%" }}>
                                        {params.row.image &&
                                            <div style={{ width: "100%", height: "100%", maxWidth: "250px" }}>
                                                <img src={params.row.fetchedImage} alt="" width="100%" height="100%" style={{ objectFit: "contain", objectPosition: "top left" }} />
                                            </div>
                                        }
                                    </div>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container item direction="column" justifyContent="flex-end" sx={{ mt: 1.5 }}>
                            {params.row.createdName || params.row.createdBy ?
                                <Typography sx={{ color: "rgba(32, 42, 68, 0.4)", fontSize: "12px", fontStyle: "italic" }}>
                                    Created {dayjs(params.row.createdAt).format("MMM DD, YYYY")} by {params.row.createdName ? params.row.createdName : params.row.createdBy.charAt(0).toUpperCase() + params.row.createdBy.substring(1)}
                                </Typography>
                                :
                                <Typography sx={{ color: "rgba(32, 42, 68, 0.4)", fontSize: "12px", fontStyle: "italic" }}>
                                    Created {dayjs(params.row.createdAt).format("MMM DD, YYYY")}
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </Paper>
            );
        }
    }];

    const handleSearch = event => {
        if (event.keyCode === 13) {
            setSearch(event.target.value);
        }
    };

    useEffect(() => {
        setUserContext(userContext => ({ ...userContext, projectsSearch: search }));
    }, [search, setUserContext]);

    const handleSort = event => {
        setSort(event.target.value);

        if (event.target.value) {
            window.localStorage.setItem("projectsSort", event.target.value);
        } else {
            window.localStorage.removeItem("projectsSort");
        }
    };

    const handleFilter = event => {
        if (event.target.name === "myProjects") {
            setMyProjects(!myProjects);
            setUserContext(userContext => ({ ...userContext, myProjects: !myProjects }));
        } else if (event.target.name === "shared") {
            setShared(!shared);
            setUserContext(userContext => ({ ...userContext, shared: !shared }));
        } else if (event.target.name === "active") {
            setActive(!active);
            setUserContext(userContext => ({ ...userContext, active: !active }));
        } else if (event.target.name === "completed") {
            setCompleted(!completed);
            setUserContext(userContext => ({ ...userContext, completed: !completed }));
        }
    };

    const handleClearFilters = () => {
        setMyProjects(false);
        setShared(false);
        setActive(false);
        setCompleted(false);
        setUserContext(userContext => ({ ...userContext, myProjects: false, shared: false, active: false, completed: false }));
    };

    const apiRef = useGridApiRef();

    const CustomToolbar = () =>
        <GridToolbarContainer sx={{ padding: 0, }}>
            <Grid container direction="column" sx={{ marginBottom: "25px" }}>
                <Grid container item direction="row" spacing={2.5} sx={{ justifyContent: "center", alignItems: "center", paddingBottom: "25px" }}>
                    <Grid item flex={1} sx={{ maxWidth: "365px" }}>
                        <Autocomplete
                            options={[]}
                            renderInput={params => (<TextField {...params} label="Search" onKeyDown={handleSearch} />)}
                            onChange={(event, value) => setSearch(value)}
                            value={search ? search : null}
                            freeSolo
                            sx={{ width: "100%" }}
                            blurOnSelect
                        />
                    </Grid>
                    <Grid item justifyItems="center">
                        <IconButton
                            onClick={() => setShowFilterMenu(!showFilterMenu)}
                            sx={{
                                backgroundColor: showFilterMenu ? "rgba(0, 0, 0, 0.1)" : "transparent",
                                ":hover": {
                                    backgroundColor: showFilterMenu ? "rgba(0, 0, 0, 0.1)" : null
                                }
                            }}
                        >
                            <FilterAltOutlinedIcon sx={{ width: 33, height: 33, color: "#495464" }} />
                        </IconButton>
                    </Grid>
                </Grid>
                {showFilterMenu &&
                    <Paper
                        sx={{
                            backgroundColor: "transparent",
                            minHeight: "350px",
                            padding: "12px",
                            justifyContent: "center",
                            boxShadow: "none",
                            width: "100%",
                            marginBottom: "25px",
                            border: "1px dotted #495464",
                            overflow: "hidden"
                        }}
                    >
                        <Grid container item direction="column" alignItems="center">
                            <Grid container item justifyContent="flex-end">
                                <IconButton onClick={() => setShowFilterMenu(false)}><CloseIcon /></IconButton>
                            </Grid>
                            <Grid container item direction="row" justifyContent="center" spacing={2.5}>
                                <Grid item sx={{ mr: "25px" }}>
                                    <Typography sx={{ fontSize: "17px", fontWeight: "bold", mb: 2.5 }}>
                                        Filter
                                    </Typography>
                                    <Typography sx={{ cursor: "pointer", width: "fit-content", mb: 1 }}>
                                        <FormControlLabel
                                            name="myProjects"
                                            checked={myProjects}
                                            control={<Checkbox sx={{ color: "rgba(32, 42, 68, 0.5)" }} />}
                                            onChange={handleFilter}
                                            label="My Projects"
                                        />
                                    </Typography>
                                    <Typography sx={{ cursor: "pointer", width: "fit-content", mb: 1 }}>
                                        <FormControlLabel
                                            name="shared"
                                            checked={shared}
                                            control={<Checkbox sx={{ color: "rgba(32, 42, 68, 0.5)" }} />}
                                            onChange={handleFilter}
                                            label="Shared"
                                        />
                                    </Typography>
                                    <Typography sx={{ cursor: "pointer", width: "fit-content", mb: 1 }}>
                                        <FormControlLabel
                                            name="active"
                                            checked={active}
                                            control={<Checkbox sx={{ color: "rgba(32, 42, 68, 0.5)" }} />}
                                            onChange={handleFilter}
                                            label="Active"
                                        />
                                    </Typography>
                                    <Typography sx={{ cursor: "pointer", width: "fit-content", mb: 1 }}>
                                        <FormControlLabel
                                            name="completed"
                                            checked={completed}
                                            control={<Checkbox sx={{ color: "rgba(32, 42, 68, 0.5)" }} />}
                                            onChange={handleFilter}
                                            label="Completed"
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography sx={{ fontSize: "17px", fontWeight: "bold", mb: 2.5 }}>
                                        Sort
                                    </Typography>
                                    <RadioGroup value={sort} onChange={handleSort}>
                                        <FormControlLabel value="name:asc" control={<Radio />} label="A-Z" sx={{ mb: 1 }} />
                                        <FormControlLabel value="createdAt:desc" control={<Radio />} label="Latest" sx={{ mb: 1 }} />
                                        <FormControlLabel value="updatedAt:desc" control={<Radio />} label="Updated" />
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" justifyContent="center" sx={{ mt: 1.5, mb: 2 }}>
                                <Grid item sx={{ mr: "25px" }}>
                                    <Button
                                        onClick={handleClearFilters}
                                        variant="outlined"
                                        sx={{ height: "45px", width: "125px" }}
                                        component="span"
                                    >
                                        <span style={{ marginLeft: "-4px", marginRight: "8px", display: "inherit" }}><ClearIcon sx={{ fontSize: 20 }} /></span>
                                        Clear
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        onClick={() => setShowFilterMenu(false)}
                                        sx={{
                                            height: "45px",
                                            width: "125px",
                                            backgroundColor: "rgba(42, 157, 143, 0.6)",
                                            color: "#495464",
                                            "&:hover": { backgroundColor: "rgba(42, 157, 143, 0.6)" }
                                        }}
                                    >
                                        Done
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                }
                <Grid container item>
                    <Button
                        onClick={() => navigate("/addproject")}
                        size="medium"
                        sx={{ height: "45px", width: "120px" }}
                    >
                        <span style={{ marginLeft: "-4px", marginRight: "8px", display: "inherit" }}><AddIcon sx={{ fontSize: 20 }} /></span>
                        Add
                    </Button>
                </Grid>
                <Grid container item spacing={1} sx={{ marginTop: myProjects || shared || active || completed ? "25px" : 0 }}>
                    {myProjects && <Grid item><Chip label="My Projects" variant="outlined" onDelete={() => handleFilter({ target: { name: "myProjects" } })} deleteIcon={<ClearIcon />} /></Grid>}
                    {shared && <Grid item><Chip label="Shared" variant="outlined" onDelete={() => handleFilter({ target: { name: "shared" } })} deleteIcon={<ClearIcon />} /></Grid>}
                    {active && <Grid item><Chip label="Active" variant="outlined" onDelete={() => handleFilter({ target: { name: "active" } })} deleteIcon={<ClearIcon />} /></Grid>}
                    {completed && <Grid item><Chip label="Completed" variant="outlined" onDelete={() => handleFilter({ target: { name: "completed" } })} deleteIcon={<ClearIcon />} /></Grid>}
                </Grid>
            </Grid>
        </GridToolbarContainer >

    useEffect(() => {
        if (!props.topRef.current) return;

        if (showFilterMenu) {
            props.topRef.current.scrollIntoView();
        }
    }, [showFilterMenu, props.topRef]);

    useEffect(() => {
        if (!listening) {
            const eventSource = new EventSource(process.env.REACT_APP_API_URL + "/projects/updates");

            eventSource.onmessage = event => {
                setUpdating(true);
            };

            return () => eventSource.close();
        }

        setListening(true);
    }, [listening, setUpdating]);

    useEffect(() => {
        setLoading(true);
    }, [props.paginationModel, search, myProjects, shared, active, completed, sort]);

    return (
        <Grid container direction="column">
            <TheSnackbar open={openSnackbar} setOpen={setOpenSnackbar} message={snackbarMessage} />
            <DataGrid
                rows={rows}
                columns={columns}
                getRowHeight={() => "auto"}
                autoHeight
                disableRowSelectionOnClick
                disableColumnMenu
                getRowId={row => row._id}
                slots={{
                    toolbar: CustomToolbar,
                    noRowsOverlay: () => {
                        return <Typography variant="body1" align="center" sx={{ color: "#495464", pt: "100px" }}>No projects found ;-(</Typography>;
                    }
                }}
                pageSizeOptions={[15, 30, 50, 100]}
                paginationMode="server"
                paginationModel={props.paginationModel}
                onPaginationModelChange={handlePageChange}
                rowCount={rowCountState}
                apiRef={apiRef}
                loading={loading}
                sx={{
                    fontSize: 16,
                    border: 0,
                    "& .MuiDataGrid-cell": {
                        padding: "0 0 20px 0",
                        border: 0,
                        "&:focus-within, &:focus": { outline: "none" }
                    },
                    "& .MuiDataGrid-footerContainer": { border: 0 },
                    "& .MuiDataGrid-columnHeaders": { display: "none" },
                    "& .MuiDataGrid-row:hover": { borderRadius: "0px", backgroundColor: "transparent" }
                }}
            />
            <Modal open={openCompletedModal} onClose={() => setOpenCompletedModal(false)}>
                <Paper
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        maxWidth: 400,
                        maxHeight: 500,
                        width: "80%",
                        overflow: "auto",
                        padding: "12px"
                    }}
                >
                    <Grid container item direction="column" alignItems="center">
                        <Grid container item justifyContent="flex-end">
                            <IconButton onClick={() => setOpenCompletedModal(false)}><CloseIcon /></IconButton>
                        </Grid>
                        <form
                            onSubmit={event => {
                                handleCompletedChange(event, projectCompleted, selectedId);
                                setProjectCompleted("");
                                setOpenCompletedModal(false);
                            }}
                        >
                            <Grid container item direction="column" alignItems="center">
                                <Grid item>
                                    <Typography sx={{ fontSize: "17px", fontWeight: "bold" }}>
                                        Set Status
                                    </Typography>
                                </Grid>
                                <Grid item sx={{ mt: 2 }}>
                                    <RadioGroup value={projectCompleted} onChange={event => setProjectCompleted(event.target.value)}>
                                        <FormControlLabel value={false} control={<Radio />} label="Active" sx={{ mb: 1 }} />
                                        <FormControlLabel value={true} control={<Radio />} label="Completed" />
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" justifyContent="center" sx={{ mt: 4, mb: 2 }}>
                                <Grid item>
                                    <Button type="submit" sx={{ height: "45px", width: "125px" }}>
                                        Done
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Paper>
            </Modal>
        </Grid>
    );
};

export default ProjectsTable;