import CropImage from "./CropImage";
import MarkImage from "./MarkImage";
import { useState, useEffect, useRef } from "react";
import { Modal, Paper, Grid, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack.js";

const EditImageModal = props => {
    const [openCropImageComponent, setOpenCropImageComponent] = useState(true);
    const [croppedImage, setCroppedImage] = useState();
    const [openMarkImageComponent, setOpenMarkImageComponent] = useState(false);
    const [markedImage, setMarkedImage] = useState();
    const paperRef = useRef(null)

    useEffect(() => {
        if (croppedImage) {
            setOpenCropImageComponent(false);
            setOpenMarkImageComponent(true);
        }
    }, [croppedImage]);

    const setProcessedImage = props.setProcessedImage;

    useEffect(() => {
        if (markedImage) {
            setProcessedImage(markedImage);
        }
    }, [markedImage, setProcessedImage]);

    const handleBack = () => {
        setCroppedImage();
        setOpenCropImageComponent(true);
        setOpenMarkImageComponent(false);
    };

    return (
        <Modal open={props.openModal} onClose={() => props.setOpenModal(false)}>
            <Paper
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    maxWidth: 800,
                    maxHeight: 1000,
                    width: "95%",
                    height: "95%",
                    bgcolor: "#e7ecef",
                    overflow: "auto"
                }}
                ref={paperRef}
            >
                <Grid container>
                    <Grid container direction="row" sx={{ padding: "2.5% 2.5% 2.5% 2.5%" }}>
                        {openMarkImageComponent &&
                            <Grid container item xs={6}>
                                <IconButton onClick={handleBack}>
                                    <ArrowBackIcon />
                                </IconButton>
                            </Grid>
                        }
                        <Grid container item xs={openMarkImageComponent ? 6 : 12} justifyContent="flex-end">
                            <IconButton onClick={() => props.setOpenModal(false)}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid container sx={{ padding: "0% 4% 4% 4%" }}>
                        {openCropImageComponent &&
                            <CropImage
                                setOpenModal={props.setOpenModal}
                                image={props.image}
                                croppedImage={croppedImage}
                                setCroppedImage={setCroppedImage}
                                paperRef={paperRef}
                            />
                        }
                        {openMarkImageComponent &&
                            <MarkImage
                                setOpenMarkImageComponent={setOpenMarkImageComponent}
                                setOpenCropImageComponent={setOpenCropImageComponent}
                                image={croppedImage}
                                markedImage={markedImage}
                                setMarkedImage={setMarkedImage}
                                setImage={setCroppedImage}
                                paperRef={paperRef}
                            />
                        }
                    </Grid>
                </Grid>
            </Paper>
        </Modal >
    );
};

export default EditImageModal;