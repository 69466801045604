import { Grid, Typography, Button, IconButton, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useRef, useState, useEffect } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CallMadeIcon from "@mui/icons-material/CallMade";
import UndoIcon from "@mui/icons-material/Undo";
import CreateIcon from "@mui/icons-material/Create";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import PanToolAltIcon from "@mui/icons-material/PanToolAlt";

const MarkImage = props => {
    const [tool, setTool] = useState("rectangle");
    const [imageWidth, setImageWidth] = useState();
    const [imageHeight, setImageHeight] = useState();
    const [scaleFactor, setScaleFactor] = useState();
    const [image, setImage] = useState();
    const canvasRef = useRef(null);
    const contextRef = useRef(null);
    const startX = useRef(null);
    const startY = useRef(null);
    const endX = useRef(null);
    const endY = useRef(null);
    const xShift = useRef(null);
    const yShift = useRef(null);
    const [canUndo, setCanUndo] = useState(false);
    const strokes = useRef([]);
    const currentStroke = useRef({});
    const isDrawing = useRef(false);
    const isMoving = useRef(false);
    const indexMoved = useRef(-1);
    const toggleButtonParams = color => {
        const border = color !== "rgba(0, 0, 0, 1)" ? "3px solid rgba(0, 0, 0, 0.3) !important" : "3px solid rgba(255, 255, 255, 0.3) !important";
        return {
            value: color,
            sx: {
                width: "25px",
                height: "25px",
                marginLeft: "10px",
                marginRight: "10px",
                borderRadius: "5px !important",
                backgroundColor: color,
                border: "3px solid transparent !important",
                ":hover": { backgroundColor: color, border: border },
                "&.Mui-selected": { backgroundColor: color + " !important", border: border }
            }
        }
    };
    const [color, setColor] = useState("rgba(237, 40, 40, 1)");
    const canvasPositionTop = useRef();
    const canvasPositionLeft = useRef();

    // Calculate the scale factor to fit entire image onto screen
    useEffect(() => {
        const initialize = async () => {
            const background = new Image();
            background.src = props.image;
            await background.decode();
            setImageHeight(background.height);
            setImageWidth(background.width);

            if (background.width >= background.height) {
                setScaleFactor(Math.min(background.width, props.paperRef.current?.clientWidth * 0.92) / background.width);
            } else {
                setScaleFactor(Math.min(background.height, props.paperRef.current?.clientHeight * 0.45) / background.height);
            }

            setImage(background);
        }

        initialize();
    }, [props.image, props.paperRef, imageHeight, imageWidth]);

    const redrawAll = () => {
        contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
        contextRef.current.drawImage(image, 0, 0, imageWidth, imageHeight);

        for (let stroke of strokes.current) {
            if (stroke.moved) {
                continue;
            }

            contextRef.current.strokeStyle = stroke.color;
            const startX = stroke.startX;
            const startY = stroke.startY;
            const endX = stroke.endX;
            const endY = stroke.endY;

            if (stroke.type === "rectangle") {
                const rectWidth = endX - startX;
                const rectHeight = endY - startY;
                contextRef.current.beginPath();
                contextRef.current.rect(startX, startY, rectWidth, rectHeight);
                contextRef.current.stroke();
            } else if (stroke.type === "arrow") {
                const dx = endX - startX;
                const dy = endY - startY;
                const headLength = (Math.sqrt(dx * dx + dy * dy) * 0.4);
                const angle = Math.atan2(dy, dx);

                // Draw arrow main line
                contextRef.current.beginPath();
                contextRef.current.moveTo(startX, startY);
                contextRef.current.lineTo(endX, endY);
                contextRef.current.stroke();

                // Draw arrow top lines
                contextRef.current.beginPath();
                contextRef.current.moveTo((endX) - headLength * Math.cos(angle - Math.PI / 6), (endY) - headLength * Math.sin(angle - Math.PI / 6));
                contextRef.current.lineTo(endX, endY);
                contextRef.current.lineTo((endX) - headLength * Math.cos(angle + Math.PI / 6), (endY) - headLength * Math.sin(angle + Math.PI / 6));
                contextRef.current.stroke();
            } else if (stroke.type === "pen") {
                contextRef.current.beginPath();

                stroke.penPath.forEach(path => {
                    contextRef.current.lineTo(path.x, path.y);
                });

                contextRef.current.stroke();
            } else if (stroke.type === "line") {
                contextRef.current.beginPath();
                contextRef.current.moveTo(startX, startY);
                contextRef.current.lineTo(endX, endY);
                contextRef.current.stroke();
            }
        }
    };

    const startDrawingRectangle = ({ nativeEvent }) => {
        const x = nativeEvent.clientX ? nativeEvent.pageX : nativeEvent.touches[0].pageX;
        const y = nativeEvent.clientY ? nativeEvent.pageY : nativeEvent.touches[0].pageY;

        startX.current = (x - canvasRef.current.getBoundingClientRect().left - window.scrollX) / scaleFactor;
        startY.current = (y - canvasRef.current.getBoundingClientRect().top - window.scrollY) / scaleFactor;

        currentStroke.current = { type: "rectangle", startX: startX.current, startY: startY.current, color: color };

        isDrawing.current = true;
    };

    const drawRectangle = ({ nativeEvent }) => {
        const x = nativeEvent.clientX ? nativeEvent.pageX : nativeEvent.touches[0].pageX;
        const y = nativeEvent.clientY ? nativeEvent.pageY : nativeEvent.touches[0].pageY;

        currentStroke.current.endX = endX.current = (x - canvasRef.current.getBoundingClientRect().left - window.scrollX) / scaleFactor;
        currentStroke.current.endY = endY.current = (y - canvasRef.current.getBoundingClientRect().top - window.scrollY) / scaleFactor;

        const rectWidth = endX.current - startX.current;
        const rectHeight = endY.current - startY.current;

        redrawAll();
        contextRef.current.strokeStyle = color;
        contextRef.current.beginPath();
        contextRef.current.rect(startX.current, startY.current, rectWidth, rectHeight);
        contextRef.current.stroke();
    };

    const startDrawingWithPen = ({ nativeEvent }) => {
        nativeEvent.stopPropagation();
        currentStroke.current = { type: "pen", penPath: [], color: color };
        contextRef.current.beginPath();
        isDrawing.current = true;
    };

    const drawWithPen = ({ nativeEvent }) => {
        const x = nativeEvent.clientX ? nativeEvent.pageX : nativeEvent.touches[0].pageX;
        const y = nativeEvent.clientY ? nativeEvent.pageY : nativeEvent.touches[0].pageY;

        currentStroke.current.penPath.push(
            {
                x: (x - canvasRef.current.getBoundingClientRect().left - window.scrollX) / scaleFactor,
                y: (y - canvasRef.current.getBoundingClientRect().top - window.scrollY) / scaleFactor
            }
        );

        redrawAll();
        contextRef.current.strokeStyle = color;
        contextRef.current.lineCap = "round";
        contextRef.current.lineJoin = "round";
        contextRef.current.beginPath();

        currentStroke.current.penPath.forEach(path => {
            contextRef.current.lineTo(path.x, path.y);
        });

        contextRef.current.stroke();
    };

    const startDrawingArrow = ({ nativeEvent }) => {
        const x = nativeEvent.clientX ? nativeEvent.pageX : nativeEvent.touches[0].pageX;
        const y = nativeEvent.clientY ? nativeEvent.pageY : nativeEvent.touches[0].pageY;

        startX.current = (x - canvasRef.current.getBoundingClientRect().left - window.scrollX) / scaleFactor;
        startY.current = (y - canvasRef.current.getBoundingClientRect().top - window.scrollY) / scaleFactor;

        currentStroke.current = { type: "arrow", startX: startX.current, startY: startY.current, color: color };

        isDrawing.current = true;
    };

    const drawArrow = ({ nativeEvent }) => {
        const x = nativeEvent.clientX ? nativeEvent.pageX : nativeEvent.touches[0].pageX;
        const y = nativeEvent.clientY ? nativeEvent.pageY : nativeEvent.touches[0].pageY;

        currentStroke.current.endX = endX.current = (x - canvasRef.current.getBoundingClientRect().left - window.scrollX) / scaleFactor;
        currentStroke.current.endY = endY.current = (y - canvasRef.current.getBoundingClientRect().top - window.scrollY) / scaleFactor;

        const dx = endX.current - startX.current;
        const dy = endY.current - startY.current;
        const headLength = Math.sqrt(dx * dx + dy * dy) * 0.4;
        const angle = Math.atan2(dy, dx);

        redrawAll();
        contextRef.current.strokeStyle = color;

        // Draw arrow main line
        contextRef.current.beginPath();
        contextRef.current.moveTo(startX.current, startY.current);
        contextRef.current.lineTo(endX.current, endY.current);
        contextRef.current.stroke();

        // Draw arrow top lines
        contextRef.current.beginPath();
        contextRef.current.moveTo(endX.current - headLength * Math.cos(angle - Math.PI / 6), endY.current - headLength * Math.sin(angle - Math.PI / 6));
        contextRef.current.lineTo(endX.current, endY.current);
        contextRef.current.lineTo(endX.current - headLength * Math.cos(angle + Math.PI / 6), endY.current - headLength * Math.sin(angle + Math.PI / 6));
        contextRef.current.stroke();
    };

    const startDrawingLine = ({ nativeEvent }) => {
        const x = nativeEvent.clientX ? nativeEvent.pageX : nativeEvent.touches[0].pageX;
        const y = nativeEvent.clientY ? nativeEvent.pageY : nativeEvent.touches[0].pageY;

        startX.current = (x - canvasRef.current.getBoundingClientRect().left - window.scrollX) / scaleFactor;
        startY.current = (y - canvasRef.current.getBoundingClientRect().top - window.scrollY) / scaleFactor;

        currentStroke.current = { type: "line", startX: startX.current, startY: startY.current, color: color };

        isDrawing.current = true;
    };

    const drawLine = ({ nativeEvent }) => {
        const x = nativeEvent.clientX ? nativeEvent.pageX : nativeEvent.touches[0].pageX;
        const y = nativeEvent.clientY ? nativeEvent.pageY : nativeEvent.touches[0].pageY;

        currentStroke.current.endX = endX.current = (x - canvasRef.current.getBoundingClientRect().left - window.scrollX) / scaleFactor;
        currentStroke.current.endY = endY.current = (y - canvasRef.current.getBoundingClientRect().top - window.scrollY) / scaleFactor;

        redrawAll();
        contextRef.current.strokeStyle = color;
        contextRef.current.beginPath();
        contextRef.current.moveTo(startX.current, startY.current);
        contextRef.current.lineTo(endX.current, endY.current);
        contextRef.current.stroke();
    };

    const startMoving = ({ nativeEvent }) => {
        const x = nativeEvent.clientX ? nativeEvent.pageX : nativeEvent.touches[0].pageX;
        const y = nativeEvent.clientY ? nativeEvent.pageY : nativeEvent.touches[0].pageY;

        const adjustedStartX = startX.current = (x - canvasRef.current.getBoundingClientRect().left - window.scrollX) / scaleFactor;
        const adjustedStartY = startY.current = (y - canvasRef.current.getBoundingClientRect().top - window.scrollY) / scaleFactor;

        let stroke = null;

        try {
            for (let i = strokes.current.length - 1; i >= 0; i--) {
                stroke = strokes.current[i];
                contextRef.current.strokeStyle = "transparent";
                contextRef.current.lineWidth = Math.min(imageWidth, imageHeight) * 0.1;
                const startX = stroke.startX;
                const startY = stroke.startY;
                const endX = stroke.endX;
                const endY = stroke.endY;

                if (stroke.type === "rectangle") {
                    const rectWidth = endX - startX;
                    const rectHeight = endY - startY;
                    contextRef.current.beginPath();
                    contextRef.current.rect(startX, startY, rectWidth, rectHeight);
                    contextRef.current.stroke();

                    if (contextRef.current.isPointInStroke(adjustedStartX, adjustedStartY)) {
                        indexMoved.current = i;
                        break;
                    }
                } else if (stroke.type === "arrow") {
                    const dx = endX - startX;
                    const dy = endY - startY;
                    const headLength = (Math.sqrt(dx * dx + dy * dy) * 0.4);
                    const angle = Math.atan2(dy, dx);

                    // Draw arrow main line
                    contextRef.current.beginPath();
                    contextRef.current.moveTo(startX, startY);
                    contextRef.current.lineTo(endX, endY);
                    contextRef.current.stroke();

                    if (contextRef.current.isPointInStroke(adjustedStartX, adjustedStartY)) {
                        indexMoved.current = i;
                        break;
                    }

                    // Draw arrow top lines
                    contextRef.current.beginPath();
                    contextRef.current.moveTo((endX) - headLength * Math.cos(angle - Math.PI / 6), (endY) - headLength * Math.sin(angle - Math.PI / 6));
                    contextRef.current.lineTo(endX, endY);
                    contextRef.current.lineTo((endX) - headLength * Math.cos(angle + Math.PI / 6), (endY) - headLength * Math.sin(angle + Math.PI / 6));
                    contextRef.current.stroke();

                    if (contextRef.current.isPointInStroke(adjustedStartX, adjustedStartY)) {
                        indexMoved.current = i;
                        break;
                    }
                } else if (stroke.type === "pen") {
                    contextRef.current.beginPath();

                    stroke.penPath.forEach(path => {
                        contextRef.current.lineTo(path.x, path.y);
                    });

                    contextRef.current.stroke();

                    if (contextRef.current.isPointInStroke(adjustedStartX, adjustedStartY)) {
                        indexMoved.current = i;
                        break;
                    }
                } else if (stroke.type === "line") {
                    contextRef.current.beginPath();
                    contextRef.current.moveTo(startX, startY);
                    contextRef.current.lineTo(endX, endY);
                    contextRef.current.stroke();

                    if (contextRef.current.isPointInStroke(adjustedStartX, adjustedStartY)) {
                        indexMoved.current = i;
                        break;
                    }
                }
            }
        } catch (err) {
            // Do nothing
        }

        contextRef.current.lineWidth = Math.min(imageWidth, imageHeight) * 0.01;
        redrawAll();

        if (indexMoved.current >= 0) {
            let updatedStroke = { ...stroke };

            if (stroke.penPath) {
                updatedStroke.penPath = structuredClone(stroke.penPath);
            }

            currentStroke.current = { ...updatedStroke, indexMoved: indexMoved.current };
            stroke.moved = true;
            isMoving.current = true;
        }
    };

    const move = ({ nativeEvent }) => {
        const x = nativeEvent.clientX ? nativeEvent.pageX : nativeEvent.touches[0].pageX;
        const y = nativeEvent.clientY ? nativeEvent.pageY : nativeEvent.touches[0].pageY;

        endX.current = (x - canvasRef.current.getBoundingClientRect().left - window.scrollX) / scaleFactor;
        endY.current = (y - canvasRef.current.getBoundingClientRect().top - window.scrollY) / scaleFactor;

        xShift.current = endX.current - startX.current;
        yShift.current = endY.current - startY.current;

        try {
            redrawAll();

            const stroke = currentStroke.current;
            contextRef.current.strokeStyle = stroke.color;
            const startX = stroke.startX + xShift.current;
            const startY = stroke.startY + + yShift.current;
            const endX = stroke.endX + xShift.current;
            const endY = stroke.endY + + yShift.current;

            if (stroke.type === "rectangle") {
                const rectWidth = endX - startX;
                const rectHeight = endY - startY;
                contextRef.current.beginPath();
                contextRef.current.rect(startX, startY, rectWidth, rectHeight);
                contextRef.current.stroke();
            } else if (stroke.type === "arrow") {
                const dx = endX - startX;
                const dy = endY - startY;
                const headLength = (Math.sqrt(dx * dx + dy * dy) * 0.4);
                const angle = Math.atan2(dy, dx);

                // Draw arrow main line
                contextRef.current.beginPath();
                contextRef.current.moveTo(startX, startY);
                contextRef.current.lineTo(endX, endY);
                contextRef.current.stroke();

                // Draw arrow top lines
                contextRef.current.beginPath();
                contextRef.current.moveTo(endX - headLength * Math.cos(angle - Math.PI / 6), endY - headLength * Math.sin(angle - Math.PI / 6));
                contextRef.current.lineTo(endX, endY);
                contextRef.current.lineTo(endX - headLength * Math.cos(angle + Math.PI / 6), endY - headLength * Math.sin(angle + Math.PI / 6));
                contextRef.current.stroke();
            } else if (stroke.type === "pen") {
                contextRef.current.beginPath();

                stroke.penPath.forEach(path => {
                    contextRef.current.lineTo(path.x + xShift.current, path.y + yShift.current);
                });

                contextRef.current.stroke();
            } else if (stroke.type === "line") {
                contextRef.current.beginPath();
                contextRef.current.moveTo(startX, startY);
                contextRef.current.lineTo(endX, endY);
                contextRef.current.stroke();
            }
        } catch (err) {
            // Do nothing
        }
    };

    const startDrawing = event => {
        if (isDrawing.current || isMoving.current || event.nativeEvent.touches?.length > 1) {
            return;
        }

        if (tool === "rectangle") {
            startDrawingRectangle(event);
        } else if (tool === "arrow") {
            startDrawingArrow(event);
        } else if (tool === "pen") {
            startDrawingWithPen(event);
        } else if (tool === "line") {
            startDrawingLine(event);
        } else if (tool === "move") {
            startMoving(event);
        }
    };

    const draw = event => {
        if (!isDrawing.current && !isMoving.current) {
            return;
        }

        if (event.nativeEvent.touches?.length > 1) {
            isDrawing.current = false;
            isMoving.current = false;
            return;
        }

        if (tool === "rectangle") {
            drawRectangle(event);
        } else if (tool === "arrow") {
            drawArrow(event);
        } else if (tool === "pen") {
            drawWithPen(event);
        } else if (tool === "line") {
            drawLine(event);
        } else if (tool === "move") {
            move(event);
        }
    };

    const stopDrawing = () => {
        if (!isDrawing.current && !isMoving.current) {
            return;
        }

        if (tool === "move") {
            const type = currentStroke.current.type;

            if (type === "rectangle" || type === "arrow" || type === "line") {
                currentStroke.current.startX += xShift.current;
                currentStroke.current.startY += yShift.current;
                currentStroke.current.endX += xShift.current;
                currentStroke.current.endY += yShift.current;
            } else if (type === "pen") {
                currentStroke.current.penPath.forEach(path => {
                    path.x += xShift.current;
                    path.y += yShift.current;
                });
            }
        }

        isDrawing.current = false;
        isMoving.current = false;
        indexMoved.current = -1;
        strokes.current.push({ ...currentStroke.current });
        setCanUndo(true);
        currentStroke.current = {};
    };

    const initialize = canvas => {
        if (canvas && !canvasRef.current) {
            canvasRef.current = canvas;
            canvasRef.current.width = imageWidth;
            canvasRef.current.height = imageHeight;
            canvasRef.current.style.background = `url(${props.image})`;
            canvasRef.current.style.backgroundSize = "cover";
            const context = canvas.getContext("2d");
            context.strokeStyle = color;
            context.lineWidth = Math.min(imageWidth, imageHeight) * 0.01;
            contextRef.current = context;
            canvasPositionTop.current = canvasRef.current.getBoundingClientRect().top;
            canvasPositionLeft.current = canvasRef.current.getBoundingClientRect().left;
            redrawAll();
        }
    };

    const handleUndo = () => {
        const removedStroke = strokes.current.pop();

        if (removedStroke.indexMoved !== null && removedStroke.indexMoved !== undefined) {
            strokes.current[removedStroke.indexMoved].moved = false;
        }

        redrawAll();
        setCanUndo(strokes.current.length > 0);
    };

    const handleNext = () => {
        props.setMarkedImage(canvasRef.current.toDataURL("image/jpeg"));
    };

    return (scaleFactor &&
        <Grid container direction="column">
            <Grid container item justifyContent="center" sx={{ mb: "25px" }}>
                <Typography variant="h5" align="center">Add Markings</Typography>
            </Grid>
            <Grid container item justifyContent="center">
                <canvas
                    ref={canvas => initialize(canvas)}
                    onMouseDown={startDrawing}
                    onMouseMove={draw}
                    onMouseUp={stopDrawing}
                    onMouseLeave={stopDrawing}
                    onTouchStart={startDrawing}
                    onTouchMove={draw}
                    onTouchEnd={stopDrawing}
                    style={{
                        width: imageWidth * scaleFactor,
                        height: imageHeight * scaleFactor,
                        marginBottom: "30px",
                        touchAction: "pinch-zoom"
                    }}
                />
            </Grid>
            <Grid container item spacing={1} justifyContent="center">
                <Grid item>
                    <IconButton
                        onClick={() => setTool("arrow")}
                        sx={{
                            color: "#274c77",
                            backgroundColor: tool === "arrow" ? "rgba(0, 0, 0, 0.1)" : "transparent",
                            borderRadius: "5px",
                            ":hover": {
                                backgroundColor: tool === "arrow" ? "rgba(0, 0, 0, 0.1)" : null,
                                borderRadius: "5px"
                            }
                        }}
                    >
                        <CallMadeIcon />
                    </IconButton>
                </Grid>
                <Grid item>
                    <IconButton
                        onClick={() => setTool("rectangle")}
                        sx={{
                            color: "#274c77",
                            backgroundColor: tool === "rectangle" ? "rgba(0, 0, 0, 0.1)" : "transparent",
                            borderRadius: "5px",
                            ":hover": {
                                backgroundColor: tool === "rectangle" ? "rgba(0, 0, 0, 0.1)" : null,
                                borderRadius: "5px"
                            }
                        }}
                    >
                        <CheckBoxOutlineBlankIcon />
                    </IconButton>
                </Grid>
                <Grid item>
                    <IconButton
                        onClick={() => setTool("pen")}
                        sx={{
                            color: "#274c77",
                            backgroundColor: tool === "pen" ? "rgba(0, 0, 0, 0.1)" : "transparent",
                            borderRadius: "5px",
                            ":hover": {
                                backgroundColor: tool === "pen" ? "rgba(0, 0, 0, 0.1)" : null,
                                borderRadius: "5px"
                            }
                        }}
                    >
                        <CreateIcon />
                    </IconButton>
                </Grid>
                <Grid item>
                    <IconButton
                        onClick={() => setTool("line")}
                        sx={{
                            color: "#274c77",
                            backgroundColor: tool === "line" ? "rgba(0, 0, 0, 0.1)" : "transparent",
                            borderRadius: "5px",
                            ":hover": {
                                backgroundColor: tool === "line" ? "rgba(0, 0, 0, 0.1)" : null,
                                borderRadius: "5px"
                            }
                        }}
                    >
                        <HorizontalRuleIcon sx={{ transform: "rotate(-45deg) scale(1.3)" }} />
                    </IconButton>
                </Grid>
                <Grid item>
                    <IconButton
                        onClick={() => setTool("move")}
                        sx={{
                            color: "#274c77",
                            backgroundColor: tool === "move" ? "rgba(0, 0, 0, 0.1)" : "transparent",
                            borderRadius: "5px",
                            ":hover": {
                                backgroundColor: tool === "move" ? "rgba(0, 0, 0, 0.1)" : null,
                                borderRadius: "5px"
                            }
                        }}
                    >
                        <PanToolAltIcon sx={{ transform: "rotate(-45deg) scale(1.3)" }} />
                    </IconButton>
                </Grid>
                <Grid item sx={{ ml: 4 }}>
                    <IconButton onClick={handleUndo} sx={{ color: "#274c77" }} disabled={!canUndo}>
                        <UndoIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid container item spacing={2.5} justifyContent="center" sx={{ marginTop: 1 }}>
                <Grid item>
                    <ToggleButtonGroup
                        value={color}
                        exclusive
                        onChange={event => setColor(event.target.value)}
                    >
                        <ToggleButton {...toggleButtonParams("rgba(237, 40, 40, 1)")} />
                        <ToggleButton {...toggleButtonParams("rgba(235, 122, 35, 1)")} />
                        <ToggleButton {...toggleButtonParams("rgba(235, 225, 35, 1)")} />
                        <ToggleButton {...toggleButtonParams("rgba(38, 148, 21, 1)")} />
                        <ToggleButton {...toggleButtonParams("rgba(21, 55, 148, 1)")} />
                        <ToggleButton {...toggleButtonParams("rgba(74, 21, 148, 1)")} />
                        <ToggleButton {...toggleButtonParams("rgba(0, 0, 0, 1)")} />
                        <ToggleButton {...toggleButtonParams("rgba(255, 255, 255, 1)")} />
                    </ToggleButtonGroup>
                </Grid>
            </Grid>
            <Grid container item justifyContent="center">
                <Button
                    onClick={handleNext}
                    sx={{
                        width: "100%",
                        marginTop: "35px",
                        height: "45px",
                        marginBottom: "35px"
                    }}
                >
                    Finish
                </Button>
            </Grid>
        </Grid >
    );
};

export default MarkImage;