import TopBar from "../components/Layout/TopBar";
import UserInfo from "../components/Settings/UserInfo";
import Users from "../components/Settings/Users";
import Defaults from "../components/Settings/Defaults";
import TheSnackbar from "../components/Common/TheSnackbar";
import { UserContext } from "../context/UserContext";
import { useState, useContext, useRef, useEffect } from "react";
import { Grid, Paper, Typography } from "@mui/material";

const Settings = () => {
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState();
    const [userContext] = useContext(UserContext);
    const topRef = useRef(null);

    useEffect(() => {
        if (!topRef.current) return;
        topRef.current.scrollIntoView();
    }, [topRef]);

    return (
        <Grid container direction="column" sx={{ maxWidth: "1000px" }} ref={topRef}>
            <TheSnackbar open={openSnackbar} setOpen={setOpenSnackbar} message={snackbarMessage} />
            <Grid container item sx={{ justifyContent: "center", paddingBottom: "25px" }}>
                <TopBar title="Settings" enableBack="true" backRoute={-1} />
            </Grid>
            <Grid container item sx={{ justifyContent: "center", paddingBottom: "35px", paddingTop: "15px" }}>
                <Typography color="black">Change settings</Typography>
            </Grid>
            <Grid container item direction="column">
                <Grid container item justifyContent="center">
                    <Paper
                        sx={{
                            backgroundColor: "transparent",
                            padding: "15px 3% 32px 3%",
                            display: "flex",
                            justifyContent: "center",
                            boxShadow: "none",
                            width: "100%",
                            maxWidth: "674px",
                            border: "1px dotted #495464",
                            marginBottom: "35px"
                        }}
                    >
                        <UserInfo setSnackbarMessage={setSnackbarMessage} setOpenSnackbar={setOpenSnackbar} />
                    </Paper>
                </Grid>
                <Grid container item justifyContent="center">
                    <Paper
                        sx={{
                            backgroundColor: "transparent",
                            padding: "15px 3% 32px 3%",
                            display: "flex",
                            justifyContent: "center",
                            boxShadow: "none",
                            width: "100%",
                            maxWidth: "674px",
                            border: "1px dotted #495464",
                            marginBottom: "35px"
                        }}
                    >
                        <Defaults setSnackbarMessage={setSnackbarMessage} setOpenSnackbar={setOpenSnackbar} />
                    </Paper>
                </Grid>
                {userContext.admin &&
                    <Grid container item justifyContent="center">
                        <Paper
                            sx={{
                                backgroundColor: "transparent",
                                padding: "15px 3% 32px 3%",
                                display: "flex",
                                justifyContent: "center",
                                boxShadow: "none",
                                width: "100%",
                                maxWidth: "674px",
                                border: "1px dotted #495464",
                                marginBottom: "35px"
                            }}
                        >
                            <Users setSnackbarMessage={setSnackbarMessage} setOpenSnackbar={setOpenSnackbar} />
                        </Paper>
                    </Grid>
                }
            </Grid>
        </Grid >
    );
};

export default Settings;