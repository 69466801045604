import './App.css';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useContext, useEffect, useCallback } from 'react';
import { UserContext } from './context/UserContext';
import axios from 'axios';
import theme from './themes/theme';
import Login from './pages/Login';
import Main from './pages/Main';
import Project from './pages/Project';
import Settings from './pages/Settings';
import Layout from './components/Layout/Layout';
import AddProject from './pages/AddProject';
import AddWork from './pages/AddWork';
import EditProject from './pages/EditProject';
import EditWork from './pages/EditWork';
import { useSearchParams } from "react-router-dom";

function App() {
  const [userContext, setUserContext] = useContext(UserContext);
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const location = useLocation();

  const verifyUser = useCallback(() => {
    const url = process.env.REACT_APP_API_URL + '/users/refreshtoken';
    const body = {};
    const config = { withCredentials: true };

    axios.post(url, body, config)
      .then(res => {
        setUserContext(userContext => ({ ...userContext, ...res.data }));
      })
      .catch(err => {
        // Do nothing
      });
  }, [setUserContext]);

  useEffect(() => {
    if (token) {
      return;
    };

    verifyUser();
  }, [verifyUser, token]);

  useEffect(() => {
    if (userContext.lastReloaded
      && userContext.lastUpdated
      && new Date(userContext.lastReloaded) < new Date(userContext.lastUpdated)
      && new Date(userContext.lastUpdated) < new Date()
    ) {
      window.location.reload();
    }
  }, [location, userContext]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <Routes>
        {!userContext.token ?
          (!token ?
            <Route>
              <Route index element={<Login />} />
              <Route path='*' element={null} />
            </Route>
            :
            <Route element={<Layout />}>
              <Route path='projects/:_id' element={<Project />} />
              <Route path='addwork' element={<AddWork />} />
              <Route path='editwork/:_id' element={<EditWork />} />
            </Route>
          )
          :
          <Route element={<Layout />}>
            <Route index element={<Navigate to='main' />} />
            <Route path='main' element={<Main />} />
            <Route path='addproject' element={<AddProject />} />
            <Route path='editproject/:_id' element={<EditProject />} />
            <Route path='addwork' element={<AddWork />} />
            <Route path='editwork/:_id' element={<EditWork />} />
            <Route path='projects/:_id' element={<Project />} />
            <Route path='settings' element={<Settings />} />
          </Route>
        }
      </Routes>
    </ThemeProvider>
  );
}

export default App;