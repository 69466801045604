import { Grid, Button, Chip, Autocomplete, TextField, Typography, Paper, Modal, Divider, RadioGroup, Radio, FormControlLabel, IconButton, Menu, MenuItem, Checkbox, InputAdornment, Link } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { DataGrid, useGridApiRef, GridToolbarContainer, GridFooter } from "@mui/x-data-grid";
import LinkIcon from "@mui/icons-material/Link";
import { useEffect, useState, useContext, useRef, useCallback } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DotMarker from "../Common/DotMarker";
import ImageMarker from "react-image-marker";
import ImageModal from "../Common/ImageModal";
import MarkedImageModal from "../Common/MarkedImageModal";
import { useParams } from "react-router-dom";
import TheSnackbar from "../Common/TheSnackbar";
import { UserContext } from "../../context/UserContext";
import { useSearchParams } from "react-router-dom";
import PrintIcon from "@mui/icons-material/Print";
import { formatCurrency } from "../../utils/utils";
import CircleIcon from "@mui/icons-material/Circle";
import { axiosInstance } from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import CloseIcon from "@mui/icons-material/Close";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import EmailIcon from "@mui/icons-material/Email";
import PushPinIcon from "@mui/icons-material/PushPin";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteDialog from "../Common/DeleteDialog";
import LinksModal from "./LinksModal";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RunCircleIcon from "@mui/icons-material/RunCircle";
import SendModal from "./SendModal";
import BlueprintModal from "./BlueprintModal";
import ContactPageIcon from "@mui/icons-material/ContactPage";

const ProjectTable = props => {
    const [openLinksModal, setOpenLinksModal] = useState(false);
    const [openSendModal, setOpenSendModal] = useState(false);
    const [openImageModal, setOpenImageModal] = useState(false);
    const [openBlueprintModal, setOpenBlueprintModal] = useState(false);
    const [image, setImage] = useState();
    const [openMarkedImageModal, setOpenMarkedImageModal] = useState(false);
    const [markedImage, setMarkedImage] = useState();
    const params = useParams();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState();
    const [rowCount, setRowCount] = useState(0);
    const [rowCountState, setRowCountState] = useState(rowCount);
    const [userContext, setUserContext] = useContext(UserContext);
    const [sort, setSort] = useState(window.localStorage.getItem("workAreasSort") !== null ? window.localStorage.getItem("workAreasSort") : "index:asc");
    const [search, setSearch] = useState(userContext.workAreasSearch ? userContext.workAreasSearch : "");
    const [data, setData] = useState([]);
    const [markers, setMarkers] = useState([]);
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const [openStatusModal, setOpenStatusModal] = useState(false);
    const [openBillingModal, setOpenBillingsModal] = useState(false);
    const [openContactModal, setOpenContactModal] = useState(false);
    const [status, setStatus] = useState("");
    const apiRef = useGridApiRef();
    const navigate = useNavigate();
    const [imageWidth, setImageWidth] = useState("200px");
    const mobileWidth = 932;
    const [anchorElement, setAnchorElement] = useState();
    const openActionsMenu = Boolean(anchorElement);
    const [showFilterMenu, setShowFilterMenu] = useState(false);
    const [myAreas, setMyAreas] = useState(userContext.myAreas !== undefined ? userContext.myAreas : userContext.myProjects);
    const [none, setNone] = useState(userContext.none !== undefined ? userContext.none : false);
    const [notStarted, setNotStarted] = useState(userContext.notStarted !== undefined ? userContext.notStarted : false);
    const [inProgress, setInProgress] = useState(userContext.inProgress !== undefined ? userContext.inProgress : false);
    const [completed, setCompleted] = useState(userContext.workAreasCompleted !== undefined ? userContext.workAreasCompleted : false);
    const [selectedId, setSelectedId] = useState();
    const [openDelete, setOpenDelete] = useState(false);
    const autocompleteParams = { fullWidth: true, freeSolo: true, autoSelect: false, autoComplete: false, autoHighlight: false };
    const [time, setTime] = useState("");
    const [expense, setExpense] = useState("");
    const [loading, setLoading] = useState(true);
    const [listeningProject, setListeningProject] = useState(false);
    const [listeningWorkAreas, setListeningWorkAreas] = useState(false);
    const [updatingProject, setUpdatingProject] = useState(true);
    const [updatingWorkAreas, setUpdatingWorkAreas] = useState(true);
    const blueprintRef = useRef(null);

    const getWindowDimensions = () => {
        const { innerWidth: width, innerHeight: height } = window;
        return { width, height };
    };

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions());
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        if (windowDimensions.width <= mobileWidth) {
            setImageWidth("100%");
        } else {
            setImageWidth("200px");
        }
    }, [windowDimensions]);

    const handleOpenActionsMenu = (event) => {
        setAnchorElement(event.currentTarget);
    };

    const handleCloseActionsMenu = () => {
        setAnchorElement(null);
    };

    const handleClickEntry = (event, _id) => {
        if (event.target.tagName === "INPUT"
            || event.target.tagName === "SPAN"
            || event.target.tagName === "LABEL"
            || event.target.tagName === "IMG"
            || (!userContext.token && !props.link.canEdit)
            || (!userContext.token && props.link.canEdit && props.project?.completed)) {

            return;
        };

        if (token) {
            navigate("/editwork/" + _id + "?token=" + token);
        } else {
            navigate("/editwork/" + _id);
        }
    };

    const handleStatusChange = (event, selectedValue, _id) => {
        event.preventDefault();

        const value = selectedValue === "none" ? "" : selectedValue;

        let url = process.env.REACT_APP_API_URL + "/workareas/" + _id + "?timestamps=false";
        const payload = { _id: _id, status: value };
        let config = {};

        if (token) {
            url += "?token=" + token;
        } else if (userContext.token) {
            config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };
        }

        axiosInstance.postForm(url, payload, config)
            .then(res => {
                // Do nothing
            })
            .catch(err => {
                // Do nothing
            });
    };

    const handleBillingChange = (time, expense, _id) => {
        let url = process.env.REACT_APP_API_URL + "/workareas/" + _id;
        const payload = { _id: _id, time: time, expense: expense };
        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

        axiosInstance.postForm(url, payload, config)
            .then(res => {
                setUserContext(userContext => ({ ...userContext, refresh: !userContext.refresh }));
            })
            .catch(err => {
                // Do nothing
            });
    };

    const handleClickImage = imgSrc => {
        setImage(imgSrc);
    };

    useEffect(() => {
        if (image) {
            setOpenImageModal(true);
        }
    }, [image]);

    const handleClickMarkedImage = async mks => {
        if (!props.project?.blueprint) return;

        setMarkedImage(await getImage(props.project.blueprint));
        setMarkers(mks);
    };

    useEffect(() => {
        if (markedImage) {
            setOpenMarkedImageModal(true);
        }
    }, [markedImage]);

    const handlePageChange = (newPaginationModel, scrollToTop = true) => {
        props.setPaginationModel(newPaginationModel);
        window.localStorage.setItem("projectPageSize", newPaginationModel.pageSize);
        setUserContext(userContext => ({ ...userContext, projectPage: newPaginationModel.page }));

        if (scrollToTop && props.topRef && props.topRef.current) {
            props.topRef.current.scrollIntoView();
        }
    };

    const getImage = useCallback(async image => {
        let url = process.env.REACT_APP_API_URL + "/images/" + image;
        let config = {};

        if (token) {
            url += "?token=" + token;
            config = { responseType: "arraybuffer" };
        } else if (userContext.token) {
            config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext, responseType: "arraybuffer" };
        }

        try {
            const fetchRes = await axiosInstance.get(url, config);
            const blob = new Blob([fetchRes.data], { type: fetchRes.headers.getContentType() });
            return URL.createObjectURL(blob);
        }
        catch (err) {
            // Do nothing
        }
    }, [token, setUserContext, userContext]);

    const setProject = props.setProject;

    useEffect(() => {
        const fetchProject = async () => {
            let url = process.env.REACT_APP_API_URL + "/projects/" + params._id;
            let config = {};

            if (token) {
                url += "?token=" + token;
            } else if (userContext.token) {
                config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };
            }

            try {
                const res = await axiosInstance.get(url, config);

                const updatedProject = { ...res.data };

                if (updatedProject.blueprint) {
                    const fetchedBlueprint = await getImage(process.env.REACT_APP_THUMBNAIL_PREFIX + updatedProject.blueprint);
                    updatedProject.fetchedBlueprint = fetchedBlueprint;
                }

                setProject(updatedProject);
                setUpdatingProject(false);
            } catch (err) {
                // Do nothing
            }
        };

        fetchProject();
    }, [userContext, setUserContext, updatingProject, params._id, token, setProject, getImage]);

    useEffect(() => {
        const fetchWorkAreas = async () => {
            if (props.paginationModel.pageSize === 0) {
                return;
            }

            let url = process.env.REACT_APP_API_URL + "/workareas?project_id=" + params._id + "&page=" + props.paginationModel.page + "&limit=" + props.paginationModel.pageSize;
            let config = {};

            if (token) {
                url += "&token=" + token;
            } else if (userContext.token) {
                config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };
            }

            if (sort) {
                url += "&sort=" + sort;
            }

            let status = "&status=[";

            if (none) {
                status += '"",';
            }

            if (notStarted) {
                status += '"Not%20Started",';
            }

            if (inProgress) {
                status += '"In%20Progress",';
            }

            if (completed) {
                status += '"Completed",';
            }

            if (status !== "&status=[") {
                status = status.substring(0, status.length - 1) + "]";
                url += status;
            }

            if (search) {
                url += "&search=" + search;
            }

            if (myAreas) {
                url += "&user=" + userContext._id;
            }

            try {
                const res = await axiosInstance.get(url, config);
                let updatedData = [...res.data.workAreas];

                for (const workArea of updatedData) {
                    workArea.fetchedImages = [];

                    for (const image of workArea.images) {
                        const fetchedImage = await getImage(process.env.REACT_APP_THUMBNAIL_PREFIX + image);
                        workArea.fetchedImages.push(fetchedImage);
                    };
                };

                setData(updatedData);
                setRowCount(res.data.total);
                setLoading(false);
                setUpdatingWorkAreas(false);
            } catch (err) {
                // Do nothing
            }
        };

        if (loading || updatingWorkAreas) {
            fetchWorkAreas();
        }
    }, [loading, userContext, setUserContext, updatingWorkAreas, sort, search, props.paginationModel, params._id, token, none, notStarted, inProgress, completed, myAreas, getImage]);

    useEffect(() => {
        setRowCountState(prevRowCountState => rowCount !== undefined ? rowCount : prevRowCountState);
    }, [rowCount, setRowCountState]);

    useEffect(() => {
        setUserContext(userContext => ({ ...userContext, workAreasSearch: search }));
    }, [search, setUserContext]);

    const handleSort = event => {
        setSort(event.target.value);

        if (event.target.value) {
            window.localStorage.setItem("workAreasSort", event.target.value);
        } else {
            window.localStorage.removeItem("workAreasSort");
        }
    };

    const handleClickStatus = (value, _id) => {
        setSelectedId(_id);
        setStatus(value);
        setOpenStatusModal(true);
    };

    const handleClickBilling = (time, expense, _id) => {
        setSelectedId(_id);
        setTime(time);
        setExpense(expense);
        setOpenBillingsModal(true);
    };

    const rows = data;

    const columns = [{
        field: "_id",
        headerName: "Work Area",
        flex: 1,
        renderCell: params => {
            return (
                <Paper
                    key={params.row._id}
                    sx={{
                        backgroundColor: "#ffffff",
                        minHeight: "165px",
                        padding: "12px",
                        display: "flex",
                        justifyContent: "center",
                        boxShadow: "none",
                        width: "100%",
                    }}
                >
                    <Grid container item>
                        <Grid container item direction="row" justifyContent="space-between" wrap="nowrap">
                            <Grid item flex={1} sx={{ pr: "12px" }}>
                                <Typography sx={{ cursor: "pointer", width: "fit-content", fontWeight: "800", fontSize: "17px" }} onClick={event => handleClickEntry(event, params.row._id)}>
                                    {"#" + (params.row.index + 1) + " " + params.row.name}
                                </Typography>
                                <Typography sx={{ whiteSpace: "pre-line", color: "rgba(0, 0, 0, 0.75)", cursor: "pointer", width: "fit-content", fontSize: "15px" }} onClick={event => handleClickEntry(event, params.row._id)}>
                                    {params.row.description}
                                </Typography>
                            </Grid>
                            <Grid item justifyContent="flex-end">
                                {(userContext.token || (props.link.canEdit && !props.project.completed) || params.row.status) &&
                                    <Chip label={params.row.status ? params.row.status : "Set status"} variant="outlined" onClick={(userContext.token || (props.link.canEdit && !props.project.completed)) ? () => handleClickStatus(params.row.status ? params.row.status : "none", params.row._id) : null} />
                                }
                                <Typography sx={{ fontSize: "15px", color: "rgba(0, 0, 0, 0.5)", fontWeight: "bold" }}>{params.row.status}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item>
                            {params.row.priority &&
                                <Typography sx={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.75)", width: "fit-content", marginTop: "5px", display: "flex", fontSize: "15px" }} onClick={event => handleClickEntry(event, params.row._id)}>
                                    <CircleIcon sx={{ width: "10px", mr: "8px", color: params.row.priority === "Low" ? "#8bacaa" : (params.row.priority === "Medium" ? "#e38d5b" : "#e76161") }} />
                                    {params.row.priority}
                                </Typography>
                            }
                            {params.row.colors?.length > 0 &&
                                <Typography sx={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.75)", width: "fit-content", marginTop: "5px", fontSize: "15px" }} onClick={event => handleClickEntry(event, params.row._id)}>
                                    <b>Colors: </b>
                                    {params.row.colors.map((color, index) => {
                                        if (index > 0) {
                                            return ", " + color.name;
                                        } else {
                                            return color.name;
                                        }
                                    })}
                                </Typography>
                            }
                            {userContext.token && params.row.employees?.length > 0 &&
                                <Typography sx={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.75)", width: "fit-content", marginTop: "5px", fontSize: "15px" }} onClick={event => handleClickEntry(event, params.row._id)}>
                                    <b>Assigned to: </b>
                                    {params.row.employees.map((employee, index) => {
                                        if (index > 0) {
                                            return ", " + employee.name;
                                        } else {
                                            return employee.name;
                                        }
                                    })}
                                </Typography>
                            }
                            {(params.row.startDate || params.row.endDate) &&
                                <Typography sx={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.75)", width: "fit-content", marginTop: "5px", fontSize: "15px" }} onClick={event => handleClickEntry(event, params.row._id)}>
                                    {params.row.startDate && <><b>Start:</b> {dayjs(params.row.startDate).format("ddd, MM/DD/YYYY")}</>}
                                    {params.row.startDate && params.row.endDate && " "}
                                    {params.row.endDate && <><b>End:</b> {dayjs(params.row.endDate).format("ddd, MM/DD/YYYY")}</>}
                                </Typography>
                            }
                            {userContext.token && params.row.billable &&
                                <Grid container item direction="row" sx={{ mt: 1.5 }}>
                                    <Grid item sx={{ mr: 1.25 }}>
                                        <Typography sx={{ color: "rgba(0, 0, 0, 0.75)", width: "fit-content", marginTop: "5px", fontSize: "15px", fontWeight: "bold" }}>
                                            Billable
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Chip
                                            label={params.row.time || params.row.expense ? ((params.row.time ? params.row.time + " hr" : "") + (params.row.time && params.row.expense ? " + " : "") + (params.row.expense ? formatCurrency(params.row.expense) : "")) : "Add T&M"}
                                            variant="outlined"
                                            onClick={() => handleClickBilling(params.row.time ? params.row.time : "", params.row.expense ? params.row.expense : "", params.row._id)}
                                            sx={{ borderRadius: "5px" }}
                                        />
                                        <Typography sx={{ fontSize: "15px", color: "rgba(0, 0, 0, 0.75)", marginTop: "5px" }}>{params.row.time || params.row.expense ? ((params.row.time ? params.row.time + " hr" : "") + (params.row.time && params.row.expense ? " + " : "") + (params.row.expense ? formatCurrency(params.row.expense) : "")) : ""}</Typography>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                        <Grid container item spacing={1.5} sx={{ marginTop: "5px" }}>
                            {props.project?.fetchedBlueprint && params.row.markers?.length > 0 &&
                                <Grid item className="blueprint" xs={windowDimensions.width <= mobileWidth ? 6 : null}>
                                    <div onClick={() => handleClickMarkedImage(params.row.markers)} style={{ width: imageWidth, aspectRatio: 1, overflow: "hidden", cursor: "pointer" }} ref={blueprintRef}>
                                        <div style={{ position: "relative", transform: `translateY(clamp(-1 * calc(100% - ${blueprintRef.current?.offsetWidth + 5.88 + "px"}), -1 * calc(${params.row.markers[0].top + "%"} - ${(blueprintRef.current?.offsetWidth * 0.5) + "px"}), 0px))` }}>
                                            <ImageMarker
                                                src={props.project.fetchedBlueprint}
                                                markers={params.row.markers}
                                                markerComponent={() => <DotMarker imageWidth={blueprintRef.current?.offsetWidth} />}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                            }
                            {params.row.fetchedImages &&
                                params.row.fetchedImages.map((img, index) => {
                                    return (
                                        <Grid item key={index} xs={windowDimensions.width <= mobileWidth ? 6 : null}>
                                            <img
                                                src={img}
                                                alt=""
                                                width={imageWidth}
                                                style={{ objectFit: "cover", cursor: "pointer", aspectRatio: 1 }}
                                                onClick={async () => handleClickImage(await getImage(params.row.images[index]))}
                                            />
                                        </Grid>
                                    );
                                })
                            }
                        </Grid>
                        <Grid container item direction="column" justifyContent="flex-end" sx={{ mt: 1.5 }}>
                            {params.row.createdName || params.row.createdBy ?
                                <Typography sx={{ color: "rgba(32, 42, 68, 0.4)", fontSize: "12px", fontStyle: "italic" }}>
                                    Created {dayjs(params.row.createdAt).format("MMM DD, YYYY")} by {params.row.createdName ? params.row.createdName : params.row.createdBy.charAt(0).toUpperCase() + params.row.createdBy.substring(1)}
                                </Typography>
                                :
                                <Typography sx={{ color: "rgba(32, 42, 68, 0.4)", fontSize: "12px", fontStyle: "italic" }}>
                                    Created {dayjs(params.row.createdAt).format("MMM DD, YYYY")}
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </Paper >
            );
        }
    }];

    const handleToggleCompleted = (value) => {
        const url = process.env.REACT_APP_API_URL + "/projects/" + props.project._id + "?timestamps=false";
        const payload = { completed: value };
        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

        axiosInstance.postForm(url, payload, config)
            .then(res => {
                if (value === false) {
                    setSnackbarMessage("Project set active");
                } else {
                    navigate("/main", { replace: true, state: { snackbarMessage: "Project set completed" } });
                }

                setOpenSnackbar(true);
                setUserContext(userContext => ({ ...userContext, refresh: !userContext.refresh }));
            })
            .catch(err => {
                // Do nothing
            });
    };

    const handleClickActionsMenuItem = item => {
        if (item === "filter") {
            setShowFilterMenu(true);
        } else if (item === "blueprint") {
            setOpenBlueprintModal(true);
        } else if (item === "edit") {
            navigate("/editproject/" + params._id);
        } else if (item === "delete") {
            setOpenDelete(true);
        } else if (item === "billing") {
            setOpenSendModal(true);
        } else if (item === "contact") {
            setOpenContactModal(true);
        } else if (item === "share") {
            setOpenLinksModal(true);
        } else if (item === "print") {
            apiRef.current.exportDataAsPrint({ hideFooter: true });
        } else if (item === "completed") {
            handleToggleCompleted(!props.project.completed);
        }

        setAnchorElement(null);
    };

    const handleFilter = event => {
        if (event.target.name === "myAreas") {
            setMyAreas(!myAreas);
            setUserContext(userContext => ({ ...userContext, myAreas: !myAreas }));
        } else if (event.target.name === "none") {
            setNone(!none);
            setUserContext(userContext => ({ ...userContext, none: !none }));
        } else if (event.target.name === "notStarted") {
            setNotStarted(!notStarted);
            setUserContext(userContext => ({ ...userContext, notStarted: !notStarted }));
        } else if (event.target.name === "inProgress") {
            setInProgress(!inProgress);
            setUserContext(userContext => ({ ...userContext, inProgress: !inProgress }));
        } else if (event.target.name === "completed") {
            setCompleted(!completed);
            setUserContext(userContext => ({ ...userContext, workAreasCompleted: !completed }));
        }
    };

    const handleClearFilters = () => {
        setMyAreas(false);
        setNone(false);
        setNotStarted(false);
        setInProgress(false);
        setCompleted(false);
        setUserContext(userContext => ({ ...userContext, myAreas: false, none: false, notStarted: false, inProgress: false, workAreasCompleted: false }));
    };

    useEffect(() => {
        if (!props.topRef.current) return;

        if (showFilterMenu) {
            props.topRef.current.scrollIntoView();
        }
    }, [showFilterMenu, props.topRef]);

    const handleDelete = () => {
        const url = process.env.REACT_APP_API_URL + "/projects/" + params._id;
        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

        axiosInstance.delete(url, config)
            .then(res => {
                navigate("/main", { replace: true, state: { snackbarMessage: "Project deleted" } });
            })
            .catch(err => {
                setOpenDelete(false);
                setSnackbarMessage(err.response.data);
                setOpenSnackbar(true);
            });
    };

    const CustomToolbar = () => <GridToolbarContainer>
        <Grid container>
            <Grid item container wrap="nowrap" sx={{ alignItems: "center", maxWidth: "1000px" }}>
                <Grid container item direction="column" flex={2} alignItems="center" justifyContent="center">
                    <Grid item alignItems="center" sx={{ paddingLeft: 2.5, paddingRight: 2.5 }}>
                        <Typography variant="h5" align="center">{props.project.name}</Typography>
                    </Grid>
                    {props.project.description &&
                        <Grid item alignItems="center" sx={{ paddingLeft: 2.5, paddingRight: 2.5, marginTop: "5px" }}>
                            <Typography variant="body1" align="center">{props.project.description}</Typography>
                        </Grid>
                    }
                </Grid>
            </Grid>
            <Grid container item sx={{ justifyContent: "center", paddingTop: "15px", paddingBottom: "15px" }}>
                <Typography color="black" variant="h6">Luu Marine Worklist</Typography>
            </Grid>
        </Grid>
    </GridToolbarContainer>

    const CustomFooter = () => <Grid container>
        <Grid container item justifyContent="flex-end">
            <GridFooter />
        </Grid>
    </Grid>

    useEffect(() => {
        if (!listeningWorkAreas && params._id) {
            const eventSource = new EventSource(process.env.REACT_APP_API_URL + "/workareas/updates?project_id=" + params._id);

            eventSource.onmessage = event => {
                setUpdatingWorkAreas(true);
            };

            return () => eventSource.close();
        }

        setListeningWorkAreas(true);
    }, [listeningWorkAreas, setUpdatingWorkAreas, params._id]);

    useEffect(() => {
        if (!listeningProject && params._id) {
            const eventSource = new EventSource(process.env.REACT_APP_API_URL + "/projects/updates?project_id=" + params._id);

            eventSource.onmessage = event => {
                setUpdatingProject(true);

                const data = JSON.parse(event.data);

                if (data.deleted) {

                    if (token) {
                        window.location.reload();
                    } else if (userContext.token) {
                        navigate("/main", { replace: true, state: { snackbarMessage: "Project deleted" } });
                    }
                }
            };

            return () => eventSource.close();
        }

        setListeningProject(true);
    }, [listeningProject, navigate, setUpdatingProject, params._id, token, userContext.token]);

    useEffect(() => {
        setLoading(true);
    }, [props.paginationModel, search, none, notStarted, inProgress, completed, sort, myAreas]);

    return (
        <Grid container direction="column">
            <TheSnackbar open={openSnackbar} setOpen={setOpenSnackbar} message={snackbarMessage} />
            <Grid container item sx={{ padding: 0, paddingBottom: "25px" }}>
                <Grid container item spacing={2.5} sx={{ justifyContent: "center", alignItems: "center", paddingBottom: "25px" }}>
                    <Grid item flex={1} sx={{ maxWidth: "365px" }}>
                        <Autocomplete
                            options={[]}
                            renderInput={params => (<TextField {...params} label="Search" />)}
                            onChange={(event, value) => setSearch(value)}
                            value={search ? search : null}
                            freeSolo
                            sx={{ width: "100%" }}
                            blurOnSelect
                        />
                    </Grid>
                    <Grid item justifyItems="center">
                        <IconButton onClick={handleOpenActionsMenu} sx={{ "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.1)" } }}>
                            <MoreHorizIcon sx={{ width: 33, height: 33, color: "#495464" }} />
                        </IconButton>
                        <Menu
                            anchorEl={anchorElement}
                            open={openActionsMenu}
                            onClose={handleCloseActionsMenu}
                            sx={{
                                mt: 1.5,
                                "& .MuiMenu-paper": {
                                    backgroundColor: "#274c77",
                                    color: "#e7ecef"
                                },
                                "& .MuiMenuItem-root": {
                                    "&:hover": {
                                        backgroundColor: "rgba(0, 0, 0, 0.2)",
                                    }
                                }
                            }}
                        >
                            <MenuItem onClick={() => handleClickActionsMenuItem("filter")}>
                                <FilterAltOutlinedIcon sx={{ mr: 2 }} />Filter/Sort
                            </MenuItem>
                            {props.project?.blueprint &&
                                <MenuItem onClick={() => handleClickActionsMenuItem("blueprint")}>
                                    <PushPinIcon sx={{ mr: 2 }} />Gen. Arrangement
                                </MenuItem>
                            }
                            {userContext.token && (props.project.person || props.project.email || props.project.phone) &&
                                <MenuItem onClick={() => handleClickActionsMenuItem("contact")}>
                                    <ContactPageIcon sx={{ mr: 2 }} />Contact Info
                                </MenuItem>
                            }
                            {userContext.token &&
                                <MenuItem onClick={() => handleClickActionsMenuItem("edit")}>
                                    <EditIcon sx={{ mr: 2 }} />Edit project info
                                </MenuItem>
                            }
                            {userContext.token &&
                                <MenuItem onClick={() => handleClickActionsMenuItem("billing")}>
                                    <EmailIcon sx={{ mr: 2 }} />Request billing
                                </MenuItem>
                            }
                            {userContext.token &&
                                <MenuItem onClick={() => handleClickActionsMenuItem("completed")}>
                                    {props.project.completed ?
                                        <><RunCircleIcon sx={{ mr: 2 }} />Set status active</>
                                        :
                                        <><CheckCircleIcon sx={{ mr: 2 }} />Set status completed</>
                                    }
                                </MenuItem>
                            }
                            <Grid container item justifyContent="center" sx={{ mt: 1.5, mb: 1.5 }}>
                                <Divider sx={{ bgcolor: "rgba(255, 255, 255, 0.3)", width: "80%" }} />
                            </Grid>
                            {userContext.token &&
                                <MenuItem onClick={() => handleClickActionsMenuItem("share")}>
                                    <LinkIcon sx={{ mr: 2 }} />Share
                                </MenuItem>
                            }
                            <MenuItem onClick={() => handleClickActionsMenuItem("print")}>
                                <PrintIcon sx={{ mr: 2 }} />Print
                            </MenuItem>
                            {userContext.token &&
                                <Grid container item justifyContent="center" sx={{ mt: 1.5, mb: 1.5 }}>
                                    <Divider sx={{ bgcolor: "rgba(255, 255, 255, 0.3)", width: "80%" }} />
                                </Grid>
                            }
                            {userContext.token &&
                                <MenuItem onClick={() => handleClickActionsMenuItem("delete")}>
                                    <DeleteIcon sx={{ mr: 2, color: "#e76161" }} /><Typography sx={{ color: "#e76161" }}>Delete project</Typography>
                                </MenuItem>
                            }
                        </Menu>
                    </Grid>
                </Grid>
                {showFilterMenu &&
                    <Paper
                        sx={{
                            backgroundColor: "transparent",
                            padding: "12px",
                            justifyContent: "center",
                            boxShadow: "none",
                            width: "100%",
                            marginBottom: "25px",
                            border: "1px dotted #495464",
                            overflow: "hidden"
                        }}
                    >
                        <Grid container item direction="column" alignItems="center">
                            <Grid container item justifyContent="flex-end">
                                <IconButton onClick={() => setShowFilterMenu(false)}><CloseIcon /></IconButton>
                            </Grid>
                            <Grid container item direction="row" justifyContent="center" spacing={2.5}>
                                <Grid item sx={{ mr: "25px" }}>
                                    <Typography sx={{ fontSize: "17px", fontWeight: "bold", mb: 2.5 }}>
                                        Filter
                                    </Typography>
                                    <Typography sx={{ cursor: "pointer", width: "fit-content", mb: 1 }}>
                                        <FormControlLabel
                                            name="myAreas"
                                            checked={myAreas}
                                            control={<Checkbox sx={{ color: "rgba(32, 42, 68, 0.5)" }} />}
                                            onChange={handleFilter}
                                            label="My Areas"
                                        />
                                    </Typography>
                                    <Typography sx={{ cursor: "pointer", width: "fit-content", mb: 1 }}>
                                        <FormControlLabel
                                            name="none"
                                            checked={none}
                                            control={<Checkbox sx={{ color: "rgba(32, 42, 68, 0.5)" }} />}
                                            onChange={handleFilter}
                                            label="None"
                                        />
                                    </Typography>
                                    <Typography sx={{ cursor: "pointer", width: "fit-content", mb: 1 }}>
                                        <FormControlLabel
                                            name="notStarted"
                                            checked={notStarted}
                                            control={<Checkbox sx={{ color: "rgba(32, 42, 68, 0.5)" }} />}
                                            onChange={handleFilter}
                                            label="Not Started"
                                        />
                                    </Typography>
                                    <Typography sx={{ cursor: "pointer", width: "fit-content", mb: 1 }}>
                                        <FormControlLabel
                                            name="inProgress"
                                            checked={inProgress}
                                            control={<Checkbox sx={{ color: "rgba(32, 42, 68, 0.5)" }} />}
                                            onChange={handleFilter}
                                            label="In Progress"
                                        />
                                    </Typography>
                                    <Typography sx={{ cursor: "pointer", width: "fit-content", mb: 1 }}>
                                        <FormControlLabel
                                            name="completed"
                                            checked={completed}
                                            control={<Checkbox sx={{ color: "rgba(32, 42, 68, 0.5)" }} />}
                                            onChange={handleFilter}
                                            label="Completed"
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography sx={{ fontSize: "17px", fontWeight: "bold", mb: 2.5 }}>
                                        Sort
                                    </Typography>
                                    <RadioGroup value={sort} onChange={handleSort}>
                                        <FormControlLabel value="name:asc" control={<Radio />} label="A-Z" sx={{ mb: 1 }} />
                                        <FormControlLabel value="index:asc" control={<Radio />} label="Number" sx={{ mb: 1 }} />
                                        <FormControlLabel value="createdAt:desc" control={<Radio />} label="Latest" sx={{ mb: 1 }} />
                                        <FormControlLabel value="updatedAt:desc" control={<Radio />} label="Updated" />
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" justifyContent="center" sx={{ mt: 1.5, mb: 2 }}>
                                <Grid item sx={{ mr: "25px" }}>
                                    <Button
                                        onClick={handleClearFilters}
                                        variant="outlined"
                                        sx={{ height: "45px", width: "125px" }}
                                        component="span"
                                    >
                                        <span style={{ marginLeft: "-4px", marginRight: "8px", display: "inherit" }}><ClearIcon sx={{ fontSize: 20 }} /></span>
                                        Clear
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button onClick={() => setShowFilterMenu(false)} sx={{ height: "45px", width: "125px" }} >
                                        Done
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                }
                {(userContext.token || (props.link.canEdit && !props.project?.completed)) &&
                    <Grid container item sx={{ marginBottom: myAreas || none || notStarted || inProgress || completed ? "25px" : 0 }}>
                        <Button
                            onClick={() => {
                                if (token) {
                                    navigate("/addwork?project_id=" + params._id + "&token=" + token);
                                } else {
                                    navigate("/addwork?project_id=" + params._id);
                                }
                            }}
                            size="medium"
                            sx={{
                                height: "45px",
                                width: "120px",
                                backgroundColor: "rgba(42, 157, 143, 0.6)",
                                color: "#495464",
                                "&:hover": { backgroundColor: "rgba(42, 157, 143, 0.6)" }
                            }}
                        >
                            <span style={{ marginLeft: "-4px", marginRight: "8px", display: "inherit" }}><AddIcon sx={{ fontSize: 20 }} /></span>
                            Add
                        </Button>
                    </Grid>
                }
                <Grid container item spacing={1}>
                    {myAreas && userContext.token && <Grid item><Chip label="My Areas" variant="outlined" onDelete={() => handleFilter({ target: { name: "myAreas" } })} deleteIcon={<ClearIcon />} /></Grid>}
                    {none && <Grid item><Chip label="None" variant="outlined" onDelete={() => handleFilter({ target: { name: "none" } })} deleteIcon={<ClearIcon />} /></Grid>}
                    {notStarted && <Grid item><Chip label="Not Started" variant="outlined" onDelete={() => handleFilter({ target: { name: "notStarted" } })} deleteIcon={<ClearIcon />} /></Grid>}
                    {inProgress && <Grid item><Chip label="In Progress" variant="outlined" onDelete={() => handleFilter({ target: { name: "inProgress" } })} deleteIcon={<ClearIcon />} /></Grid>}
                    {completed && <Grid item><Chip label="Completed" variant="outlined" onDelete={() => handleFilter({ target: { name: "completed" } })} deleteIcon={<ClearIcon />} /></Grid>}
                </Grid>
            </Grid>
            <DataGrid
                rows={rows}
                columns={columns}
                getRowHeight={() => "auto"}
                autoHeight
                disableRowSelectionOnClick
                disableColumnMenu
                getRowId={row => row._id}
                slots={{
                    toolbar: CustomToolbar,
                    footer: CustomFooter,
                    noRowsOverlay: () => {
                        return <Typography variant="body1" align="center" sx={{ color: "#495464", pt: "100px" }}>
                            {!none && !notStarted && !inProgress && !completed && !search ?
                                "Click add to get started"
                                :
                                "No work found"
                            }
                        </Typography>;
                    }
                }}
                pageSizeOptions={[15, 30, 50, 100]}
                paginationMode="server"
                paginationModel={props.paginationModel}
                onPaginationModelChange={handlePageChange}
                rowCount={rowCountState}
                apiRef={apiRef}
                loading={loading}
                sx={{
                    fontSize: 16,
                    border: 0,
                    "& .MuiDataGrid-cell": {
                        padding: "0 0 20px 0",
                        border: 0,
                        "&:focus-within, &:focus": { outline: "none" }
                    },
                    "& .MuiDataGrid-footerContainer": { border: 0 },
                    "& .MuiDataGrid-columnHeaders": { display: "none" },
                    "& .MuiDataGrid-row:hover": { borderRadius: "0px", backgroundColor: "transparent" },
                    "& .MuiDataGrid-toolbarContainer": { display: "none" }, // Toolbar container
                    "& .css-1nkjo5j-MuiTypography-root": { display: "none" }, // Status print display
                    "& .css-qp8ov-MuiTypography-root": { display: "none" }, // T&M print display
                    "@media print": {
                        fontFamily: "Arial",
                        color: "#000000",
                        display: "table", // Fit columns onto page and avoid break inside rows,
                        "& .MuiDataGrid-virtualScroller": { display: "table", overflow: "visible" },
                        "& .image-marker__marker": {
                            "& div": {
                                boxShadow: "inset 0 0 0 15px #6096ba"
                            }
                        },
                        "& .MuiDataGrid-toolbarContainer": { display: "flex" }, // Toolbar container
                        "& .css-1csiwpf-MuiButtonBase-root-MuiChip-root": { display: "none" }, // Status button
                        "& .css-dv2pso-MuiButtonBase-root-MuiChip-root": { display: "none" }, // T&M button
                        "& .css-1nkjo5j-MuiTypography-root": { display: "flex" }, // Status print display
                        "& .css-qp8ov-MuiTypography-root": { display: "flex" } // T&M print display
                    }
                }}
            />
            <Modal open={openStatusModal} onClose={() => setOpenStatusModal(false)}>
                <Paper
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        maxWidth: 400,
                        maxHeight: 500,
                        width: "80%",
                        overflow: "auto",
                        padding: "12px"
                    }}
                >
                    <Grid container item direction="column" alignItems="center">
                        <Grid container item justifyContent="flex-end">
                            <IconButton onClick={() => setOpenStatusModal(false)}><CloseIcon /></IconButton>
                        </Grid>
                        <form
                            onSubmit={event => {
                                handleStatusChange(event, status, selectedId);
                                setStatus("");
                                setOpenStatusModal(false);
                            }}
                        >
                            <Grid container item direction="column" alignItems="center">
                                <Grid item>
                                    <Typography sx={{ fontSize: "17px", fontWeight: "bold" }}>
                                        Set Status
                                    </Typography>
                                </Grid>
                                <Grid item sx={{ mt: 2 }}>
                                    <RadioGroup value={status} onChange={event => setStatus(event.target.value)}>
                                        <FormControlLabel value="none" control={<Radio />} label="None" sx={{ mb: 1 }} />
                                        <FormControlLabel value="Not Started" control={<Radio />} label="Not Started" sx={{ mb: 1 }} />
                                        <FormControlLabel value="In Progress" control={<Radio />} label="In Progress" sx={{ mb: 1 }} />
                                        <FormControlLabel value="Completed" control={<Radio />} label="Completed" />
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" justifyContent="center" sx={{ mt: 4, mb: 2 }}>
                                <Grid item>
                                    <Button type="submit" sx={{ height: "45px", width: "125px" }}>
                                        Done
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Paper>
            </Modal>
            <Modal open={openBillingModal} onClose={() => setOpenBillingsModal(false)}>
                <Paper
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        maxWidth: 400,
                        maxHeight: 500,
                        width: "80%",
                        overflow: "auto",
                        padding: "12px"
                    }}
                >
                    <Grid container item direction="column" alignItems="center">
                        <Grid container item justifyContent="flex-end">
                            <IconButton onClick={() => setOpenBillingsModal(false)}><CloseIcon /></IconButton>
                        </Grid>
                        <form
                            onSubmit={event => {
                                event.preventDefault();
                                handleBillingChange(time, expense, selectedId);
                                setTime("");
                                setStatus("");
                                setOpenBillingsModal(false);
                            }}
                        >
                            <Grid container item direction="column" alignItems="center" spacing={2.5}>
                                <Grid item>
                                    <Typography sx={{ fontSize: "17px", fontWeight: "bold" }}>
                                        Time & Materials
                                    </Typography>
                                </Grid>
                                <Grid container item sx={{ mt: 2.5, width: "90%" }}>
                                    <Autocomplete
                                        {...autocompleteParams}
                                        options={[]}
                                        renderInput={params => (<TextField
                                            {...params}
                                            label="Time"
                                            value={time}
                                            inputProps={{ ...params.inputProps, autoCapitalize: "none" }}
                                            InputProps={{ endAdornment: <InputAdornment position="end" sx={{ pr: "5px" }}>hr</InputAdornment> }}
                                            InputLabelProps={{ shrink: true }} helperText="Example: 56.5 hr"
                                        />)}
                                        onInputChange={(event, value) => setTime(value)}
                                        inputValue={String(time)}
                                        disableClearable
                                        sx={{ "& .MuiFormHelperText-root.Mui-disabled": { color: "rgba(187, 191, 202, 0.7)" } }}
                                    />
                                </Grid>
                                <Grid container item sx={{ mt: 1, width: "90%" }}>
                                    <Autocomplete
                                        {...autocompleteParams}
                                        options={[]}
                                        renderInput={params => (<TextField
                                            {...params}
                                            label="Materials"
                                            value={expense}
                                            inputProps={{ ...params.inputProps, autoCapitalize: "none" }}
                                            InputProps={{ startAdornment: <InputAdornment position="start" sx={{ pl: "5px" }}>$</InputAdornment> }}
                                            InputLabelProps={{ shrink: true }} helperText="Example: $1250.00"
                                        />)}
                                        onInputChange={(event, value) => setExpense(value)}
                                        inputValue={String(expense)}
                                        disableClearable
                                        sx={{ "& .MuiFormHelperText-root.Mui-disabled": { color: "rgba(187, 191, 202, 0.7)" } }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" justifyContent="center" sx={{ mt: 4, mb: 2 }}>
                                <Grid item>
                                    <Button type="submit" sx={{ height: "45px", width: "125px" }}>
                                        Update
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Paper>
            </Modal>
            <Modal open={openContactModal} onClose={() => setOpenContactModal(false)}>
                <Paper
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        maxWidth: 400,
                        maxHeight: 500,
                        width: "80%",
                        overflow: "auto",
                        bgcolor: "#e7ecef",
                        padding: "12px"
                    }}
                >
                    <Grid container item direction="column" alignItems="center">
                        <Grid container item justifyContent="flex-end">
                            <IconButton onClick={() => setOpenContactModal(false)}><CloseIcon /></IconButton>
                        </Grid>
                        <Grid container item direction="column" alignItems="center" spacing={2.5} sx={{ mb: 8 }}>
                            <Grid item>
                                <Typography sx={{ fontSize: "17px", fontWeight: "bold" }}>
                                    Contact Info
                                </Typography>
                            </Grid>
                            <Grid container item direction="column" spacing={1.5} sx={{ justifyContent: "center", alignItems: "center", mt: 1, width: "90%" }} >
                                <Grid item>
                                    <Typography>{props.project.person}</Typography>
                                </Grid>
                                <Grid item>
                                    <Link onClick={() => window.location = "mailto:" + props.project.email}>
                                        <Typography>{props.project.email}</Typography>
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link onClick={() => window.location = "tel:" + props.project.phone}>
                                        <Typography>{props.project.phone}</Typography>
                                    </Link>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Modal>
            {openDelete && <DeleteDialog open={openDelete} setOpen={setOpenDelete} handleDelete={handleDelete} type="project" />}
            {openImageModal && <ImageModal openModal={openImageModal} setOpenModal={setOpenImageModal} image={image} setImage={setImage} />}
            {openMarkedImageModal && <MarkedImageModal openModal={openMarkedImageModal} setOpenModal={setOpenMarkedImageModal} image={markedImage} setImage={setMarkedImage} markers={markers} />}
            {openLinksModal && <LinksModal openModal={openLinksModal} setOpenModal={setOpenLinksModal} projectId={params._id} setSnackbarMessage={setSnackbarMessage} setOpenSnackbar={setOpenSnackbar} />}
            {openSendModal && <SendModal openModal={openSendModal} setOpenModal={setOpenSendModal} project={props.project} setSnackbarMessage={setSnackbarMessage} setOpenSnackbar={setOpenSnackbar} />}
            {openBlueprintModal && <BlueprintModal openModal={openBlueprintModal} setOpenModal={setOpenBlueprintModal} project={props.project} />}
        </Grid>
    );
};

export default ProjectTable;