import { Grid, Button, Typography, Radio, RadioGroup, FormControlLabel, FormControl } from "@mui/material";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { useState, useRef } from "react";
import "react-image-crop/dist/ReactCrop.css";

const CropImage = props => {
    const [crop, setCrop] = useState();
    const imgRef = useRef();
    const maxDisplayHeight = props.paperRef.current?.clientHeight * 0.6;
    const displayHeight = imgRef.current ? Math.min(maxDisplayHeight, imgRef.current.height) : 0;
    const ratio = imgRef.current ? (displayHeight / imgRef.current.height) : 0;
    const displayWidth = imgRef.current ? Math.min(ratio * imgRef.current.width, imgRef.current.width) : 0;
    const [aspect, setAspect] = useState(1);

    const handleNext = () => {
        const image = imgRef.current;

        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        const outputWidth = (crop.width / 100) * image.naturalWidth;
        const outputHeight = (crop.height / 100) * image.naturalHeight;

        canvas.width = outputWidth;
        canvas.height = outputHeight;

        context.drawImage(
            image,
            (crop.x / 100) * image.naturalWidth,
            (crop.y / 100) * image.naturalHeight,
            (crop.width / 100) * image.naturalWidth,
            (crop.height / 100) * image.naturalHeight,
            0,
            0,
            outputWidth,
            outputHeight
        );

        const base64Image = canvas.toDataURL("image/png");
        props.setCroppedImage(base64Image);
    };

    const onImageLoad = event => {
        const { naturalWidth: width, naturalHeight: height } = event.currentTarget;

        const crop = centerCrop(
            makeAspectCrop({ unit: "%", width: 100, }, 1, width, height),
            width,
            height
        );

        setCrop(crop);
    };

    return (
        <Grid container direction="column">
            <Grid container item justifyContent="center" sx={{ mb: "25px" }}>
                <Typography variant="h5" align="center">Crop Image</Typography>
            </Grid>
            <Grid container item sx={{ justifyContent: "center", paddingBottom: "35px" }}>
                <Typography color="black">Crop the image to the desired size</Typography>
            </Grid>
            <Grid container item justifyContent="center" sx={{ mb: "30px" }}>
                <ReactCrop crop={crop} aspect={aspect < 0 ? undefined : aspect} onChange={(_, percentCrop) => setCrop(percentCrop)}>
                    <img
                        ref={imgRef}
                        alt=""
                        src={props.image}
                        onLoad={onImageLoad}
                        crossOrigin="anonymous"
                        style={{
                            width: displayWidth ? displayWidth : "auto",
                            height: displayHeight ? displayHeight : "auto"
                        }}
                    />
                </ReactCrop>
            </Grid>
            <Grid container item justifyContent="center" sx={{ mb: "30px" }}>
                <FormControl sx={{ textAlign: "center" }}>
                    <RadioGroup
                        row
                        defaultValue={1}
                        value={aspect}
                        onChange={event => setAspect(event.target.value === "1" ? 1 : -1)}
                    >
                        <FormControlLabel value={1} control={<Radio />} label="Square" sx={{ marginRight: "0px", justifyContent: "center" }} />
                        <FormControlLabel value={-1} control={<Radio />} label="Free-form" sx={{ marginLeft: "16px", marginRight: "0px", justifyContent: "center" }} />
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid container item justifyContent="center">
                <Button
                    onClick={handleNext}
                    sx={{
                        width: "100%",
                        marginTop: "20px",
                        height: "45px",
                        marginBottom: "35px",
                        backgroundColor: "rgba(42, 157, 143, 0.6)",
                        color: "#495464",
                        "&:hover": { backgroundColor: "rgba(42, 157, 143, 0.6)" }
                    }}
                >
                    Next
                </Button>
            </Grid>
        </Grid>
    );
};

export default CropImage;