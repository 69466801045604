import { Grid, Typography, Button } from "@mui/material";
import { useRef, useState, useEffect } from "react";

const MarkImage = props => {
    const [isDrawing, setIsDrawing] = useState(false);
    const [imageLoaded, setImageLoaded] = useState(false);

    const [imageWidth, setImageWidth] = useState();
    const [imageHeight, setImageHeight] = useState();
    const [scaleFactor, setScaleFactor] = useState();

    const canvasRef = useRef(null);
    const contextRef = useRef(null);

    const startX = useRef(null);
    const startY = useRef(null);

    useEffect(() => {
        let image = new Image();

        image.onload = () => {
            setImageHeight(image.height);
            setImageWidth(image.width);

            if (props.paperRef.current?.clientWidth > props.paperRef.current?.clientHeight) {
                if (image.width > imageHeight) {
                    setScaleFactor(Math.min(imageWidth, props.paperRef.current?.clientWidth * 0.85) / imageWidth);
                } else {
                    setScaleFactor(Math.min(imageHeight, props.paperRef.current?.clientHeight * 0.6) / imageHeight);
                }
            } else {
                if (image.width >= imageHeight) {
                    setScaleFactor(Math.min(imageWidth, props.paperRef.current?.clientWidth * 0.85) / imageWidth);
                } else {
                    setScaleFactor(Math.min(imageHeight, props.paperRef.current?.clientHeight * 0.6) / imageHeight);
                }
            }
        };

        image.src = props.image;
    }, [props.image, props.paperRef, imageHeight, imageWidth]);

    useEffect(() => {
        if (scaleFactor) {
            setImageLoaded(true);
        }
    }, [scaleFactor])

    const startDrawingRectangle = ({ nativeEvent }) => {
        nativeEvent.stopPropagation();

        const x = nativeEvent.clientX ? nativeEvent.clientX : nativeEvent.touches[0].clientX;
        const y = nativeEvent.clientY ? nativeEvent.clientY : nativeEvent.touches[0].clientY;

        startX.current = x - canvasRef.current.getBoundingClientRect().left;
        startY.current = y - canvasRef.current.getBoundingClientRect().top;

        setIsDrawing(true);
    };

    const drawRectangle = ({ nativeEvent }) => {
        if (!isDrawing) {
            return;
        }

        nativeEvent.stopPropagation();

        const x = nativeEvent.clientX ? nativeEvent.clientX : nativeEvent.touches[0].clientX;
        const y = nativeEvent.clientY ? nativeEvent.clientY : nativeEvent.touches[0].clientY;

        const newMouseX = x - canvasRef.current.getBoundingClientRect().left;
        const newMouseY = y - canvasRef.current.getBoundingClientRect().top;

        const rectWidth = (newMouseX - startX.current) / scaleFactor;
        const rectHeight = (newMouseY - startY.current) / scaleFactor;

        contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

        contextRef.current.strokeRect(startX.current / scaleFactor, startY.current / scaleFactor, rectWidth, rectHeight);

        contextRef.current.globalCompositeOperation = "destination-over";

        const background = new Image();
        background.src = props.image;

        background.onload = () => {
            contextRef.current.drawImage(background, 0, 0, imageWidth, imageHeight);
        };
    };

    const stopDrawingRectangle = () => {
        setIsDrawing(false);
    };

    const initialize = canvas => {
        if (canvas && !canvasRef.current) {
            canvasRef.current = canvas;

            const context = canvas.getContext("2d");
            context.strokeStyle = "red";
            context.lineWidth = Math.min(imageWidth, imageHeight) * 0.01;
            contextRef.current = context;
        }
    };

    const handleNext = () => {
        if (!canvasRef.background) {
            const background = new Image();
            background.src = props.image;

            background.onload = () => {
                contextRef.current.drawImage(background, 0, 0, imageWidth, imageHeight);
                props.setMarkedImage(canvasRef.current.toDataURL("image/jpeg"));
            };
        } else {
            props.setMarkedImage(canvasRef.current.toDataURL("image/jpeg"));
        }
    };

    return (imageLoaded &&
        <Grid container direction="column">
            <Grid container item justifyContent="center" sx={{ mb: "25px" }}>
                <Typography variant="h5" align="center">Mark Area</Typography>
            </Grid>
            <Grid container item sx={{ justifyContent: "center", paddingBottom: "35px" }}>
                <Typography color="black">Make a box around the work area</Typography>
            </Grid>
            <Grid container item justifyContent="center">
                <canvas
                    ref={canvas => initialize(canvas)}
                    onMouseDown={startDrawingRectangle}
                    onMouseMove={drawRectangle}
                    onMouseUp={stopDrawingRectangle}
                    onMouseLeave={stopDrawingRectangle}
                    onTouchStart={startDrawingRectangle}
                    onTouchMove={drawRectangle}
                    onTouchEnd={stopDrawingRectangle}
                    width={imageWidth}
                    height={imageHeight}
                    style={{
                        width: imageWidth * scaleFactor,
                        height: imageHeight * scaleFactor,
                        background: `url(${props.image})`,
                        backgroundSize: "cover",
                        marginBottom: "30px",
                        touchAction: "none"
                    }}
                />
            </Grid>
            <Grid container item justifyContent="center">
                <Button
                    onClick={handleNext}
                    sx={{
                        width: "100%",
                        marginTop: "20px",
                        height: "45px",
                        marginBottom: "35px"
                    }}
                >
                    Finish
                </Button>
            </Grid>
        </Grid>
    );
};

export default MarkImage;