import { useState, useEffect, useContext } from "react";
import { Button, Typography, Grid, TextField, Box } from "@mui/material";
import ErrorBox from "../components/Common/ErrorBox";
import PasswordAdornment from "../components/Common/PasswordAdornment";
import { UserContext } from "../context/UserContext";
import axios from "axios";
import logo from "../images/Luu Marine.png";

const Login = () => {
    const [inputs, setInputs] = useState({ username: "", password: "" });
    const [disableButton, setDisableButton] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [, setUserContext] = useContext(UserContext);
    const textFieldParams = { onChange: event => handleChange(event), sx: { width: 265 }, variant: "outlined" };

    const handleChange = event => setInputs(inputs => ({ ...inputs, [event.target.name]: event.target.value }));

    const handleSubmit = event => {
        event.preventDefault();
        setDisableButton(true);

        const url = process.env.REACT_APP_API_URL + "/users/login";
        const body = inputs;
        const config = { withCredentials: true };

        axios.post(url, body, config)
            .then(res => {
                setUserContext(userContext => ({ ...userContext, ...res.data }));
                window.location.href = "/main";
            })
            .catch(err => {
                setErrorMessage(err.response.data);
                setShowErrorMessage(true);
                setDisableButton(false);
            });
    };

    useEffect(() => setDisableButton(inputs.username === "" || inputs.password === ""), [inputs]);

    const isTouchScreenDevice = () => {
        try {
            document.createEvent("TouchEvent");
            return true;
        } catch (err) {
            return false;
        }
    };

    return (
        <div align="center">
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "30px 0px 80px 0px",
                marginTop: "5vh",
                verticalAlign: "center",
                maxWidth: 535,
                height: 500
            }}>
                <Grid container direction="column" space={2.5} sx={{ marginBottom: "20px" }}>
                    <Grid item sx={{ marginBottom: "30px" }}>
                        <img src={logo} alt="" style={{ height: "115px" }} />
                    </Grid>
                    <Grid item sx={{ marginBottom: "15px" }}>
                        <Typography variant="h5" color="black">
                            Welcome
                        </Typography>
                    </Grid>
                    <Grid item sx={{ marginBottom: "30px" }}>
                        <Typography variant="body1" color="black">
                            Sign in below to get started
                        </Typography>
                    </Grid>
                </Grid>
                <form onSubmit={handleSubmit}>
                    <Grid container direction="column" spacing={2.5}>
                        <Grid item>
                            <TextField
                                type="text"
                                name="username"
                                autoFocus={!isTouchScreenDevice()}
                                label="Username"
                                {...textFieldParams}
                                inputProps={{ autoCapitalize: "none" }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                type={!showPassword ? "password" : "text"}
                                name="password"
                                label="Password"
                                {...textFieldParams}
                                InputProps={{ endAdornment: <PasswordAdornment showPassword={showPassword} setShowPassword={setShowPassword} /> }}
                            />
                        </Grid>
                        <ErrorBox showErrorMessage={showErrorMessage} errorMessage={errorMessage} />
                        <Grid item align="center" width="100%"><Button disabled={disableButton} type="submit" sx={{ width: "100%", marginTop: "30px", height: "45px" }}>Sign In</Button></Grid>
                    </Grid>
                </form>
            </Box>
        </div>
    );
};

export default Login;