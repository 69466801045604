import { Grid, Button, TextField, Typography, IconButton, Autocomplete, InputAdornment, ToggleButtonGroup, ToggleButton, FormControlLabel, Checkbox, Paper } from "@mui/material";
import { useState, useEffect, useContext, useRef, useCallback } from "react";
import ErrorBox from "../components/Common/ErrorBox";
import { UserContext } from "../context/UserContext";
import { axiosInstance } from "../utils/utils";
import TopBar from "../components/Layout/TopBar";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { CalendarIcon } from "@mui/x-date-pickers";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddIcon from "@mui/icons-material/Add";
import CircleIcon from "@mui/icons-material/Circle";
import ImageMarker from "react-image-marker";
import DotMarker from "../components/Common/DotMarker";
import UndoIcon from "@mui/icons-material/Undo";
import EditImageModal from "../components/Projects/EditImageModal";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import axios from "axios";

const AddWork = props => {
    const [disableSubmitButton, setDisableSubmitButton] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const autocompleteParams = { fullWidth: true, freeSolo: true, autoSelect: false, autoComplete: true, autoHighlight: false, blurOnSelect: true };
    const [userContext, setUserContext] = useContext(UserContext);
    const topRef = useRef(null);
    const headingParams = { variant: "body1", sx: { fontWeight: "bold", color: "#495464", mr: 1 } };
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [images, setImages] = useState([]);
    const [image, setImage] = useState();
    const [selectedImageIndex, setSelectedImageIndex] = useState();
    const [selectedWorkAreaIndex, setSelectedWorkAreaIndex] = useState();
    const [workAreas, setWorkAreas] = useState([]);
    const [openEditImageModal, setOpenEditImageModal] = useState(false);
    const gridItemParams = { sx: { width: "50%" } };
    const [processedImage, setProcessedImage] = useState();
    const [blueprint, setBlueprint] = useState();
    const [, setModified] = useState(false);
    const lastColorFieldRef = useRef();
    const lastEmployeeFieldRef = useRef();
    const [addingColor, setAddingColor] = useState(false);
    const [addingEmployee, setAddingEmployee] = useState(false);
    const [openStartDate, setOpenStartDate] = useState(false);
    const [openEndDate, setOpenEndDate] = useState(false);
    const [workAreasCount, setWorkAreasCount] = useState(0);
    const token = searchParams.get("token");
    const [link, setLink] = useState({ canEdit: false, linkUser: "", token: "" });
    const [options, setOptions] = useState({ colors: [], employees: [] });
    const [selectedColorIndex, setSelectedColorIndex] = useState(0);
    const [selectedEmployeeIndex, setSelectedEmployeeIndex] = useState(0);
    const colorRef = useRef();
    const employeeRef = useRef();
    const blueprintRef = useRef(null);
    const [blueprintWidth, setBlueprintWidth] = useState();

    useEffect(() => {
        if (token) {
            const getLinkInfo = () => {
                const url = process.env.REACT_APP_API_URL + "/links/" + token + "?token=" + token;

                axios.get(url)
                    .then(res => {
                        setLink(res.data);
                    })
                    .catch(err => {
                        // Do nothing
                    });
            };

            getLinkInfo();
        }
    }, [token]);

    const handleDateClick = (setOpen, workAreaIndex) => {
        setSelectedWorkAreaIndex(workAreaIndex);
        setOpen(true);
    };

    const pickerSlotProps = (setOpen, Icon, name, format, workArea, workAreaIndex) => {
        return ({
            textField: {
                InputProps: { placeholder: null, value: workArea[name] ? dayjs(workArea[name]).format(format) : "", endAdornment: (<InputAdornment position="end" onClick={() => handleDateClick(setOpen, workAreaIndex)} sx={{ cursor: "pointer" }}><Icon /></InputAdornment>) },
                readOnly: true,
                sx: { input: { cursor: "pointer" }, width: "100%" },
                onClick: () => handleDateClick(setOpen, workAreaIndex),
                InputLabelProps: { shrink: true }
            },
            actionBar: {
                actions: ["clear", "accept"],
            }
        });
    };

    useEffect(() => {
        const fetchWorkAreasCount = () => {
            let url = process.env.REACT_APP_API_URL + "/workareas/count?project_id=" + searchParams.get("project_id");
            let config = {};

            if (token) {
                url += "&token=" + token;
            } else if (userContext.token) {
                config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };
            }

            axiosInstance.get(url, config)
                .then(res => {
                    setWorkAreasCount(parseInt(res.data));
                })
                .catch(err => {
                    // Do nothing
                });
        };

        fetchWorkAreasCount();
    }, [token, searchParams, setUserContext, userContext]);

    useEffect(() => {
        const fieldsNotEmpty = () => {
            return workAreas.length > 0 && workAreas.every(workArea => workArea.name);
        };

        setDisableSubmitButton(!fieldsNotEmpty());
    }, [workAreas]);

    const handleRemoveImage = (imagesIndex, workAreaIndex) => {
        let updatedWorkAreas = [...workAreas];
        updatedWorkAreas[workAreaIndex].images.splice(imagesIndex, 1);
        setWorkAreas(updatedWorkAreas);

        let updatedImages = [...images];
        updatedImages[workAreaIndex].splice(imagesIndex, 1);
        setImages(updatedImages);

        setModified(true);
    };

    const handleAddImages = (event, workAreaIndex) => {
        let updatedWorkAreas = [...workAreas];
        let updatedImages = [...images];

        for (let i = 0; i < event.target.files.length; i++) {
            updatedWorkAreas[workAreaIndex].images.push(event.target.files[i])
            updatedImages[workAreaIndex].push(URL.createObjectURL(event.target.files[i]));
        }

        setWorkAreas(updatedWorkAreas);
        setImages(updatedImages);
        setModified(true);
    };

    const handleImageClick = (imagesIndex, workAreaIndex) => {
        setSelectedImageIndex(imagesIndex);
        setSelectedWorkAreaIndex(workAreaIndex);
        setImage(images[workAreaIndex][imagesIndex]);
        setOpenEditImageModal(true);
    };

    useEffect(() => {
        if (processedImage) {
            fetch(processedImage)
                .then(res => {
                    res.blob().then(imgBlob => {
                        const img = new File([imgBlob], "image", { type: imgBlob.type });

                        let updatedWorkAreas = [...workAreas];
                        let updatedImages = [...images];

                        updatedWorkAreas[selectedWorkAreaIndex].images[selectedImageIndex] = img;
                        updatedImages[selectedWorkAreaIndex][selectedImageIndex] = processedImage;

                        setWorkAreas(updatedWorkAreas);
                        setImages(updatedImages);

                        setProcessedImage();
                    });
                });

            setOpenEditImageModal(false);
        }
    }, [processedImage, images, selectedImageIndex, selectedWorkAreaIndex, workAreas]);

    const getImage = useCallback(async image => {
        let url = process.env.REACT_APP_API_URL + "/images/" + image;
        let config = {};

        if (token) {
            url += "?token=" + token;
            config = { responseType: "arraybuffer" };
        } else if (userContext.token) {
            config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext, responseType: "arraybuffer" };
        }

        try {
            const fetchRes = await axiosInstance.get(url, config);
            const blob = new Blob([fetchRes.data], { type: fetchRes.headers.getContentType() });
            return URL.createObjectURL(blob);
        }
        catch (err) {
            // Do nothing
        }
    }, [token, setUserContext, userContext]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setDisableSubmitButton(true);

        let url = process.env.REACT_APP_API_URL + "/workareas?project_id=" + searchParams.get("project_id");
        let config = {};

        if (token) {
            url += "&token=" + token;
        } else if (userContext.token) {
            config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };
        }

        for (const workArea of workAreas) {
            let payload = { ...workArea };

            payload.colors = payload.colors.filter(color => color.name);
            payload.employees = payload.employees.filter(employee => employee.name);

            if (payload.startDate === null) {
                payload.startDate = "";
            }

            if (payload.endDate === null) {
                payload.endDate = "";
            }

            try {
                await axiosInstance.postForm(url, payload, config);
            } catch (err) {
                setErrorMessage("Unable to add some or all work");
                setShowErrorMessage(true);
                setDisableSubmitButton(false);
                return;
            }
        }

        if (token) {
            navigate("/projects/" + searchParams.get("project_id") + "?token=" + token, { replace: true, state: { snackbarMessage: "Work added", goToFirstPage: true } });
        } else {
            navigate("/projects/" + searchParams.get("project_id"), { replace: true, state: { snackbarMessage: "Work added", goToFirstPage: true } });
        }
    };

    useEffect(() => {
        const fetchBlueprint = async () => {
            let url = process.env.REACT_APP_API_URL + "/projects/" + searchParams.get("project_id");
            let config = {};

            if (token) {
                url += "?token=" + token;
            } else if (userContext.token) {
                config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };
            }

            try {
                const res = await axiosInstance.get(url, config);
                setBlueprint(await getImage(res.data.blueprint));
            } catch (err) {
                // Do nothing
            }
        };

        fetchBlueprint();
    }, [token, userContext, setUserContext, getImage, searchParams]);

    const handleAddEmployee = (workAreaIndex) => {
        let updatedWorkAreas = [...workAreas];
        updatedWorkAreas[workAreaIndex].employees.push({ name: "" });
        setWorkAreas(updatedWorkAreas);
        setAddingEmployee(true);
        setModified(true);
    };

    const handleEmployeeChange = (value, employeeIndex, workAreaIndex) => {
        let updatedWorkAreas = [...workAreas];
        updatedWorkAreas[workAreaIndex].employees[employeeIndex].name = value;
        setWorkAreas(updatedWorkAreas);
        setModified(true);
    };

    const handleRemoveEmployee = (employeeIndex, workAreaIndex) => {
        let updatedWorkAreas = [...workAreas];
        updatedWorkAreas[workAreaIndex].employees.splice(employeeIndex, 1);
        setWorkAreas(updatedWorkAreas);
        setModified(true);
    };

    const handleAddColor = (workAreaIndex) => {
        let updatedWorkAreas = [...workAreas];
        updatedWorkAreas[workAreaIndex].colors.push({ name: "" });
        setWorkAreas(updatedWorkAreas);
        setAddingColor(true);
        setModified(true);
    };

    const handleColorChange = (value, colorIndex, workAreaIndex) => {
        let updatedWorkAreas = [...workAreas];
        updatedWorkAreas[workAreaIndex].colors[colorIndex].name = value;
        setWorkAreas(updatedWorkAreas);
        setModified(true);
    };

    const handleRemoveColor = (colorIndex, workAreaIndex) => {
        let updatedWorkAreas = [...workAreas];
        updatedWorkAreas[workAreaIndex].colors.splice(colorIndex, 1);
        setWorkAreas(updatedWorkAreas);
        setModified(true);
    };

    useEffect(() => {
        if (!topRef.current) return;
        topRef.current.scrollIntoView();
    }, [topRef]);

    useEffect(() => {
        if (lastColorFieldRef.current && addingColor) {
            setAddingColor(false);
            lastColorFieldRef.current.focus();
        }
    }, [addingColor]);

    useEffect(() => {
        if (lastEmployeeFieldRef.current && addingEmployee) {
            setAddingEmployee(false);
            lastEmployeeFieldRef.current.focus();
        }
    }, [addingEmployee]);

    const handleAddWorkArea = () => {
        setWorkAreas(workAreas => [...workAreas, { name: "", description: "", startDate: null, endDate: null, billable: false, priority: "", images: [], markers: [], colors: [{ name: "" }], employees: [{ name: "" }] }]);
        setImages(images => [...images, []]);
        setModified(true);
    };

    const handleRemoveWorkArea = (workAreaIndex) => {
        let updatedWorkAreas = [...workAreas];
        updatedWorkAreas.splice(workAreaIndex, 1);
        setWorkAreas(updatedWorkAreas);

        let updatedImages = [...images];
        updatedImages.splice(workAreaIndex, 1);
        setImages(updatedImages);

        setModified(true);
    };

    const handleChangeWorkAreaField = (name, value, workAreaIndex) => {
        let updatedWorkAreas = [...workAreas];
        updatedWorkAreas[workAreaIndex][name] = value;
        setWorkAreas(updatedWorkAreas);
        setModified(true);
    };

    const handleDateChange = (name, value, workAreaIndex) => {
        if (!Date.parse(value)) {
            handleChangeWorkAreaField(name, null, workAreaIndex);
        } else {
            handleChangeWorkAreaField(name, value, workAreaIndex);
        }

        setModified(true);
    };

    const handleAddMultiple = (event) => {
        let updatedWorkAreas = [...workAreas];
        let updatedImages = [...images];

        for (let i = 0; i < event.target.files.length; i++) {
            updatedWorkAreas.push({ name: "", description: "", startDate: null, endDate: null, billable: false, priority: "", images: [event.target.files[i]], markers: [], colors: [{ name: "" }], employees: [{ name: "" }] });
            updatedImages.push([URL.createObjectURL(event.target.files[i])]);
        }

        setWorkAreas(updatedWorkAreas);
        setImages(updatedImages);
        setModified(true);
    };

    useEffect(() => {
        const fetchOptions = () => {
            let url = process.env.REACT_APP_API_URL + "/workareas/options";
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            axiosInstance.get(url, config)
                .then(res => {
                    setOptions(options => ({ ...options, colors: res.data.colorOptions }));

                    let url = process.env.REACT_APP_TIME_APP_API_URL + "/users/options";
                    const config = { params: { token: userContext.token }, userContext: userContext, setUserContext: setUserContext };

                    axiosInstance.get(url, config)
                        .then(rslts => {
                            setOptions(options => ({ ...options, employees: rslts.data.userOptions }));
                        })
                        .catch(err => {
                            // Do nothing
                        });
                })
                .catch(err => {
                    // Do nothing
                });
        };

        fetchOptions();
    }, [userContext, setUserContext]);

    const isTouchScreenDevice = () => {
        try {
            document.createEvent("TouchEvent");
            return true;
        } catch (err) {
            return false;
        }
    };

    return (
        <Grid container direction="column" sx={{ maxWidth: "1000px" }} ref={topRef}>
            <Grid container item sx={{ justifyContent: "center", paddingBottom: "25px" }}>
                <TopBar title="Add Work" enableBack modified={false} backRoute={-1} link={link} />
            </Grid>
            <Grid container item sx={{ justifyContent: "center", paddingBottom: "35px" }}>
                <Typography color="black">What work are we doing?</Typography>
            </Grid>
            <Grid container item justifyContent="center">
                <form onSubmit={handleSubmit} style={{ width: "100%", maxWidth: "524px" }}>
                    {workAreas.map((workArea, workAreaIndex) => {
                        return (
                            <Paper
                                key={workAreaIndex}
                                sx={{
                                    backgroundColor: "transparent",
                                    padding: "3%",
                                    display: "flex",
                                    justifyContent: "center",
                                    boxShadow: "none",
                                    width: "100%",
                                    border: "1px dotted #495464",
                                    marginBottom: "35px"
                                }}
                            >
                                <Grid container item spacing={4} sx={{ mb: 2.5 }}>
                                    <Grid container item alignItems="center" justifyContent="spacing-between" wrap="nowrap">
                                        <Grid container item flex={0.5} alignItems="center" />
                                        <Grid container item flex={2} justifyContent="center" alignItems="center">
                                            <Grid item sx={{ paddingLeft: 2.5, paddingRight: 2.5 }}>
                                                <Typography sx={{ fontSize: "20px", fontWeight: "bold", textDecoration: "underline", align: "center" }}>Work #{workAreasCount + workAreaIndex + 1}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container item flex={0.5} justifyContent="flex-end">
                                            <IconButton tabIndex={-1} onClick={() => handleRemoveWorkArea(workAreaIndex)}><RemoveCircleOutlineOutlinedIcon /></IconButton>
                                        </Grid>
                                    </Grid>
                                    <Grid container item spacing={2.5} alignItems="center">
                                        <Grid container item alignItems="center">
                                            <Typography {...headingParams}>Dates</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item container spacing={2.5}>
                                        <Grid item {...gridItemParams}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    label="Start date"
                                                    value={workArea.startDate}
                                                    onChange={value => handleDateChange("startDate", value, workAreaIndex)}
                                                    sx={{ width: "100%" }}
                                                    open={selectedWorkAreaIndex === workAreaIndex && openStartDate}
                                                    onOpen={() => setSelectedWorkAreaIndex(workAreaIndex)}
                                                    onClose={() => setOpenStartDate(false)}
                                                    slotProps={pickerSlotProps(setOpenStartDate, CalendarIcon, "startDate", "MM/DD/YYYY", workArea, workAreaIndex)}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item {...gridItemParams}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    label="End date"
                                                    value={workArea.endDate}
                                                    onChange={value => handleDateChange("endDate", value, workAreaIndex)}
                                                    sx={{ width: "100%" }}
                                                    open={selectedWorkAreaIndex === workAreaIndex && openEndDate}
                                                    onClose={() => setOpenEndDate(false)}
                                                    slotProps={pickerSlotProps(setOpenEndDate, CalendarIcon, "endDate", "MM/DD/YYYY", workArea, workAreaIndex)}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                    </Grid>
                                    <Grid container item spacing={2.5} alignItems="center">
                                        <Grid container item alignItems="center">
                                            <Typography {...headingParams}>Work</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container item>
                                        <Autocomplete
                                            {...autocompleteParams}
                                            options={[]}
                                            renderInput={params => (<TextField {...params} label="Work area / Part" value={workArea.name} inputProps={{ ...params.inputProps, autoCapitalize: "words" }} InputLabelProps={{ shrink: true, required: true }} helperText="Example: Transom Door" />)}
                                            onInputChange={(event, value) => handleChangeWorkAreaField("name", value, workAreaIndex)}
                                            inputValue={workArea.name}
                                        />
                                    </Grid>
                                    <Grid container item>
                                        <Autocomplete
                                            {...autocompleteParams}
                                            options={[]}
                                            renderInput={params => (<TextField {...params} multiline rows={3} label="Work description" value={workArea.description} inputProps={{ ...params.inputProps, autoCapitalize: "sentences" }} InputLabelProps={{ shrink: true }} helperText="Example: Repair blister" />)}
                                            onInputChange={(event, value) => handleChangeWorkAreaField("description", value, workAreaIndex)}
                                            inputValue={workArea.description}
                                            blurOnSelect={false}
                                        />
                                    </Grid>
                                    <Grid container item spacing={2.5} alignItems="center">
                                        <Grid container item alignItems="center">
                                            <Typography {...headingParams}>Images</Typography>
                                        </Grid>
                                        <Grid container item direction="column" spacing={2.5}>
                                            {workArea.images.length > 0 &&
                                                <Grid container item>
                                                    {workArea.images.map((image, imagesIndex) => {
                                                        return (
                                                            <Grid container item key={imagesIndex} spacing={2.5}>
                                                                <Grid item>
                                                                    <Typography sx={{ color: "rgba(32, 42, 68, 0.6)", textAlign: "center", fontSize: "14px", mt: imagesIndex !== 0 ? 2.5 : 1.75 }}>
                                                                        Image #{imagesIndex + 1}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid container item spacing={2.5}>
                                                                    <Grid item xs={10}>
                                                                        <img
                                                                            src={images[workAreaIndex][imagesIndex]}
                                                                            alt=""
                                                                            width="100%"
                                                                            style={{ objectFit: "cover", aspectRatio: 1, cursor: "pointer" }}
                                                                            onClick={() => handleImageClick(imagesIndex, workAreaIndex)}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item container alignContent="center" xs={2}>
                                                                        <IconButton tabIndex={-1} onClick={() => handleRemoveImage(imagesIndex, workAreaIndex)}><RemoveCircleIcon /></IconButton>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        )
                                                    })}
                                                </Grid>
                                            }
                                            <Grid container item spacing={2.5} sx={{ mt: 0.75 }}>
                                                <Grid container item xs={10}>
                                                    <div style={{ width: "100%", borderRadius: "5px" }}>
                                                        <label>
                                                            <input hidden type="file" multiple accept="image/*" onChange={event => handleAddImages(event, workAreaIndex)} onClick={event => event.target.value = null} />
                                                            <Grid container item sx={{ alignContent: "center", height: "300px", borderRadius: "5px", border: "2px dotted #495464", cursor: "pointer" }}>
                                                                <Grid container item justifyContent="center">
                                                                    <Typography sx={{ color: "rgba(32, 42, 68, 0.6)", textAlign: "center", fontSize: "14px" }}>Click to add work area image(s)</Typography>
                                                                </Grid>
                                                                <Grid container item spacing={3.5} justifyContent="center" sx={{ mt: "10px" }}>
                                                                    <Grid item>
                                                                        <AddPhotoAlternateOutlinedIcon sx={{ width: 35, height: 35 }} />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </label>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container item spacing={2.5} alignItems="center">
                                        <Grid container item alignItems="center">
                                            <Typography {...headingParams}>Colors</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container item spacing={2.5}>
                                        {workArea.colors.length > 0 ?
                                            workArea.colors.map((color, colorIndex) => {
                                                return (
                                                    <Grid container item key={colorIndex} ref={selectedWorkAreaIndex === workAreaIndex && selectedColorIndex === colorIndex ? colorRef : null}>
                                                        <Grid container item flex={1} sx={{ minWidth: "200px", mr: 2.5 }}>
                                                            <Autocomplete
                                                                {...autocompleteParams}
                                                                options={options.colors}
                                                                renderInput={params => (<TextField
                                                                    {...params}
                                                                    label={"Color #" + (colorIndex + 1)}
                                                                    value={color.name}
                                                                    inputProps={{ ...params.inputProps, autoCapitalize: "words" }}
                                                                    InputLabelProps={{ shrink: true }}
                                                                    helperText={color.name ? null : "Example: Awlgrip Snow White"}
                                                                    inputRef={colorIndex === workAreas[workAreaIndex].colors.length - 1 ? lastColorFieldRef : null}
                                                                    onFocus={() => {
                                                                        setSelectedWorkAreaIndex(workAreaIndex);
                                                                        setSelectedColorIndex(colorIndex);
                                                                    }}
                                                                />)}
                                                                onOpen={() => {
                                                                    if (isTouchScreenDevice()) {
                                                                        setTimeout(() => {
                                                                            colorRef.current.style.marginBottom = "90vh";
                                                                            colorRef.current.scrollIntoView();
                                                                        }, 100);
                                                                    }
                                                                }}
                                                                onClose={() => colorRef.current.style.marginBottom = "0"}
                                                                onInputChange={(event, value) => handleColorChange(value, colorIndex, workAreaIndex)}
                                                                inputValue={color.name}
                                                                openOnFocus
                                                            />
                                                        </Grid>
                                                        <Grid container item alignItems="center" xs={2}>
                                                            {colorIndex > 0 && <IconButton tabIndex={-1} onClick={() => handleRemoveColor(colorIndex, workAreaIndex)} sx={{ mb: color.name ? null : "23px" }}><RemoveCircleIcon /></IconButton>}
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })
                                            :
                                            <Grid item>
                                                <Typography sx={{ color: "rgba(32, 42, 68, 0.6)", textAlign: "center", fontSize: "14px" }}>No color added ;-(</Typography>
                                            </Grid>
                                        }
                                        <Grid container item>
                                            <Button
                                                onClick={() => handleAddColor(workAreaIndex)}
                                                variant="outlined"
                                                sx={{ height: "45px", width: "150px", color: "#495464" }}
                                                component="span"
                                            >
                                                <span style={{ marginLeft: "-4px", marginRight: "8px", display: "inherit" }}><AddIcon sx={{ fontSize: 20 }} /></span>
                                                Color
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    {userContext.token &&
                                        <>
                                            <Grid container item spacing={2.5} alignItems="center">
                                                <Grid container item alignItems="center">
                                                    <Typography {...headingParams}>Assigned To</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container item spacing={2.5}>
                                                {workArea.employees.length > 0 ?
                                                    workArea.employees.map((employee, employeeIndex) => {
                                                        return (
                                                            <Grid container item key={employeeIndex} ref={selectedWorkAreaIndex === workAreaIndex && selectedEmployeeIndex === employeeIndex ? employeeRef : null}>
                                                                <Grid container item flex={1} sx={{ minWidth: "200px", mr: 2.5 }}>
                                                                    <Autocomplete
                                                                        {...autocompleteParams}
                                                                        options={options.employees}
                                                                        renderInput={params => (<TextField
                                                                            {...params}
                                                                            label={"Employee #" + (employeeIndex + 1)}
                                                                            value={employee.name}
                                                                            inputProps={{ ...params.inputProps, autoCapitalize: "words" }}
                                                                            InputLabelProps={{ shrink: true }}
                                                                            helperText={employee.name ? null : "Example: Yonatan Cordova"}
                                                                            inputRef={employeeIndex === workAreas[workAreaIndex].employees.length - 1 ? lastEmployeeFieldRef : null}
                                                                            onFocus={() => {
                                                                                setSelectedWorkAreaIndex(workAreaIndex);
                                                                                setSelectedEmployeeIndex(employeeIndex);
                                                                            }}
                                                                        />)}
                                                                        onOpen={() => {
                                                                            if (isTouchScreenDevice()) {
                                                                                setTimeout(() => {
                                                                                    employeeRef.current.style.marginBottom = "90vh";
                                                                                    employeeRef.current.scrollIntoView();
                                                                                }, 100);
                                                                            }
                                                                        }}
                                                                        onClose={() => employeeRef.current.style.marginBottom = "0"}
                                                                        onInputChange={(event, value) => handleEmployeeChange(value, employeeIndex, workAreaIndex)}
                                                                        inputValue={employee.name}
                                                                        openOnFocus
                                                                    />
                                                                </Grid>
                                                                <Grid container item alignItems="center" xs={2}>
                                                                    {employeeIndex > 0 && <IconButton tabIndex={-1} onClick={() => handleRemoveEmployee(employeeIndex, workAreaIndex)} sx={{ mb: employee.name ? null : "23px" }}><RemoveCircleIcon /></IconButton>}
                                                                </Grid>
                                                            </Grid>
                                                        )
                                                    })
                                                    :
                                                    <Grid item>
                                                        <Typography sx={{ color: "rgba(32, 42, 68, 0.6)", textAlign: "center", fontSize: "14px" }}>No employee assigned ;-(</Typography>
                                                    </Grid>
                                                }
                                                <Grid container item alignItems="center">
                                                    <Button
                                                        onClick={() => handleAddEmployee(workAreaIndex)}
                                                        variant="outlined"
                                                        sx={{ height: "45px", width: "150px", color: "#495464" }}
                                                        component="span"
                                                    >
                                                        <span style={{ marginLeft: "-4px", marginRight: "8px", display: "inherit" }}><AddIcon sx={{ fontSize: 20 }} /></span>
                                                        Employee
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </>
                                    }
                                    <Grid container item spacing={2.5} alignItems="center">
                                        <Grid container item alignItems="center">
                                            <Typography {...headingParams}>Priority</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container item spacing={2.5}>
                                        <Grid item>
                                            <Typography sx={{ color: "rgba(32, 42, 68, 0.6)", fontSize: "14px" }}>Select the work priority level:</Typography>
                                        </Grid>
                                        <Grid item>
                                            <ToggleButtonGroup
                                                exclusive
                                                onChange={((event, selectedPriority) => handleChangeWorkAreaField("priority", selectedPriority, workAreaIndex))}
                                                value={workArea.priority}
                                                sx={{ "& .Mui-selected": { color: "rgba(0, 0, 0, 0.65)" } }}
                                                size="medium"
                                            >
                                                <ToggleButton value="">None</ToggleButton>
                                                <ToggleButton value="Low"><CircleIcon sx={{ width: "10px", mr: "8px", color: "#8bacaa" }} />Low</ToggleButton>
                                                <ToggleButton value="Medium"><CircleIcon sx={{ width: "10px", mr: "8px", color: "#e38d5b" }} />Medium</ToggleButton>
                                                <ToggleButton value="High"><CircleIcon sx={{ width: "10px", mr: "8px", color: "#e76161" }} />High</ToggleButton>
                                            </ToggleButtonGroup>
                                        </Grid>
                                    </Grid>
                                    <Grid container item spacing={2.5} alignItems="center">
                                        <Grid container item alignItems="center">
                                            <Typography {...headingParams}>Billable</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container item direction="column" spacing={2.5}>
                                        <Grid item>
                                            <FormControlLabel checked={workArea.billable} control={<Checkbox />} onChange={event => handleChangeWorkAreaField("billable", !workArea.billable, workAreaIndex)} label="Work is billable T&M" />
                                        </Grid>
                                    </Grid>
                                    {blueprint &&
                                        <>
                                            <Grid container item spacing={2.5} alignItems="center">
                                                <Grid container item alignItems="center">
                                                    <Typography {...headingParams}>General Arrangement</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container item direction="column" spacing={2.5}>
                                                <Grid item>
                                                    <Typography sx={{ color: "rgba(32, 42, 68, 0.6)", fontSize: "14px" }}>Mark the area on the GA.</Typography>
                                                </Grid>
                                                <Grid item onLoad={() => setBlueprintWidth(blueprintRef.current.offsetWidth)} ref={blueprintRef}>
                                                    <ImageMarker
                                                        src={blueprint}
                                                        markers={workArea.markers}
                                                        onAddMarker={marker => {
                                                            const updatedWorkAreas = [...workAreas];
                                                            updatedWorkAreas[workAreaIndex].markers.push(marker);
                                                            setWorkAreas(updatedWorkAreas);
                                                            setModified(true);
                                                        }}
                                                        markerComponent={() => <DotMarker imageWidth={blueprintWidth} />}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        onClick={() => {
                                                            const updatedWorkAreas = [...workAreas];
                                                            updatedWorkAreas[workAreaIndex].markers = updatedWorkAreas[workAreaIndex].markers.slice(0, -1);
                                                            setWorkAreas(updatedWorkAreas);
                                                            setModified(true);
                                                        }}
                                                        variant="outlined"
                                                        sx={{ height: "45px", width: "150px" }}
                                                        component="span"
                                                        disabled={workArea.markers?.length === 0}
                                                    >
                                                        <span style={{ marginLeft: "-4px", marginRight: "8px", display: "inherit" }}><UndoIcon sx={{ fontSize: 20 }} /></span>
                                                        Undo
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </>
                                    }
                                </Grid>
                            </Paper>
                        )
                    })}
                    <Paper
                        sx={{
                            backgroundColor: "transparent",
                            padding: "30px 12px 30px 12px",
                            display: "flex",
                            justifyContent: "center",
                            boxShadow: "none",
                            width: "100%",
                            height: "250px",
                            alignItems: "center",
                            border: "1px dotted #495464",
                            marginBottom: "30px"
                        }}
                    >
                        <Grid container item>
                            <Grid container item justifyContent="center">
                                <Typography sx={{ color: "rgba(32, 42, 68, 0.6)", textAlign: "center", fontSize: "14px" }}>Select a method to add work</Typography>
                            </Grid>
                            <Grid container item justifyContent="center" spacing={3.5} sx={{ mt: "10px" }}>
                                <Grid item>
                                    <label>
                                        <input hidden type="file" multiple accept="image/*" onChange={event => handleAddMultiple(event)} onClick={event => event.target.value = null} />
                                        <IconButton component="span"><CollectionsOutlinedIcon sx={{ width: 35, height: 35, color: "#495464" }} /></IconButton>
                                    </label>
                                </Grid>
                                <Grid item>
                                    <IconButton onClick={handleAddWorkArea}><AddCircleOutlineOutlinedIcon sx={{ width: 35, height: 35, color: "#495464" }} /></IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                    <br />
                    <ErrorBox showErrorMessage={showErrorMessage} errorMessage={errorMessage} />
                    <Button disabled={disableSubmitButton} type="submit" sx={{ width: "100%", marginTop: "20px", height: "45px", marginBottom: "35px" }}>Done</Button>
                </form >
            </Grid >
            {openEditImageModal &&
                <EditImageModal
                    openModal={openEditImageModal}
                    setOpenModal={setOpenEditImageModal}
                    image={image}
                    processedImage={processedImage}
                    setProcessedImage={setProcessedImage}
                />
            }
        </Grid >
    );
};

export default AddWork;