import { Grid, Button, TextField, Typography, IconButton, Autocomplete, InputAdornment, ToggleButtonGroup, ToggleButton, FormControlLabel, Checkbox } from "@mui/material";
import { useState, useEffect, useContext, useRef, useCallback } from "react";
import ErrorBox from "../components/Common/ErrorBox";
import { UserContext } from "../context/UserContext";
import { axiosInstance } from "../utils/utils";
import TopBar from "../components/Layout/TopBar";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { CalendarIcon } from "@mui/x-date-pickers";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddIcon from "@mui/icons-material/Add";
import CircleIcon from "@mui/icons-material/Circle";
import ImageMarker from "react-image-marker";
import DotMarker from "../components/Common/DotMarker";
import UndoIcon from "@mui/icons-material/Undo";
import EditImageModal from "../components/Projects/EditImageModal";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DeleteDialog from "../components/Common/DeleteDialog";
import axios from "axios";

const EditWork = props => {
    const [disableSubmitButton, setDisableSubmitButton] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const autocompleteParams = { fullWidth: true, freeSolo: true, autoSelect: false, autoComplete: true, autoHighlight: false, blurOnSelect: true };
    const [userContext, setUserContext] = useContext(UserContext);
    const topRef = useRef(null);
    const headingParams = { variant: "body1", sx: { fontWeight: "bold", color: "#495464", mr: 1 } };
    const navigate = useNavigate();
    const [images, setImages] = useState([[]]);
    const [image, setImage] = useState();
    const [selectedImageIndex, setSelectedImageIndex] = useState();
    const [details, setDetails] = useState([{ name: "", description: "", index: null, number: null, startDate: null, endDate: null, billable: false, priority: "", images: [], markers: [], colors: [{ name: "" }], employees: [{ name: "" }] }]);
    const [openEditImageModal, setOpenEditImageModal] = useState(false);
    const gridItemParams = { sx: { width: "50%" } };
    const [processedImage, setProcessedImage] = useState();
    const [blueprint, setBlueprint] = useState();
    const [, setModified] = useState(false);
    const lastColorFieldRef = useRef();
    const lastEmployeeFieldRef = useRef();
    const [addingColor, setAddingColor] = useState(false);
    const [addingEmployee, setAddingEmployee] = useState(false);
    const [openStartDate, setOpenStartDate] = useState(false);
    const [openEndDate, setOpenEndDate] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [imagesModified, setImagesModified] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const params = useParams();
    const [numberOptions, setNumberOptions] = useState([]);
    const numberRef = useRef();
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const [link, setLink] = useState({ canEdit: false, linkUser: "", token: "" });
    const [options, setOptions] = useState({ colors: [], employees: [] });
    const [selectedColorIndex, setSelectedColorIndex] = useState(0);
    const [selectedEmployeeIndex, setSelectedEmployeeIndex] = useState(0);
    const colorRef = useRef();
    const employeeRef = useRef();
    const blueprintRef = useRef(null);
    const [blueprintWidth, setBlueprintWidth] = useState();

    useEffect(() => {
        if (token) {
            const getLinkInfo = () => {
                const url = process.env.REACT_APP_API_URL + "/links/" + token + "?token=" + token;

                axios.get(url)
                    .then(res => {
                        setLink(res.data);
                    })
                    .catch(err => {
                        // Do nothing
                    });
            };

            getLinkInfo();
        }
    }, [token]);

    const pickerSlotProps = (setOpen, Icon, name, format, workArea) => {
        return ({
            textField: {
                InputProps: { placeholder: null, value: details[name] ? dayjs(details[name]).format(format) : "", endAdornment: (<InputAdornment position="end" onClick={() => setOpen(true)} sx={{ cursor: "pointer" }}><Icon /></InputAdornment>) },
                readOnly: true,
                sx: { input: { cursor: "pointer" }, width: "100%" },
                onClick: () => setOpen(true),
                InputLabelProps: { shrink: true }
            },
            actionBar: {
                actions: ["clear", "accept"],
            }
        });
    };

    const isTouchScreenDevice = () => {
        try {
            document.createEvent("TouchEvent");
            return true;
        } catch (err) {
            return false;
        }
    };

    useEffect(() => {
        const fetchWorkAreasCount = () => {
            let url = process.env.REACT_APP_API_URL + "/workareas/count?project_id=" + details.projectId;
            let config = {};

            if (token) {
                url += "&token=" + token;
            } else if (userContext.token) {
                config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };
            }

            axiosInstance.get(url, config)
                .then(res => {
                    const count = parseInt(res.data);
                    let updatedNumbers = [];

                    for (let i = 0; i < count; i++) {
                        updatedNumbers.push((i + 1).toString());
                    }

                    setNumberOptions(updatedNumbers);
                })
                .catch(err => {
                    // Do nothing
                });
        };

        if (details.projectId) {
            fetchWorkAreasCount();
        }
    }, [token, details.projectId, setUserContext, userContext]);

    const handleChange = (name, value) => {
        setDetails(pairs => ({ ...pairs, [name]: value }));
        setModified(true);
    };

    const handleRemoveImage = index => {
        let updatedDetails = { ...details };
        updatedDetails.images.splice(index, 1);
        setDetails(updatedDetails);

        let updatedImages = [...images];
        updatedImages.splice(index, 1);
        setImages(updatedImages);

        setImagesModified(true);
        setModified(true);
    };

    useEffect(() => {
        const fieldsNotEmpty = () => {
            return details.name;
        };

        setDisableSubmitButton(!fieldsNotEmpty());
    }, [details]);

    const handleAddImages = (event, value) => {
        let updatedDetails = { ...details };
        let updatedImages = [...images];

        for (let i = 0; i < event.target.files.length; i++) {
            updatedDetails.images.push(event.target.files[i])
            updatedImages.push(URL.createObjectURL(event.target.files[i]));
        }

        setDetails(updatedDetails);
        setImages(updatedImages);
        setImagesModified(true);
        setModified(true);
    };

    const handleImageClick = index => {
        setSelectedImageIndex(index);
        setImage(images[index]);
        setOpenEditImageModal(true);
    };

    const handleDateChange = (name, value) => {
        if (!Date.parse(value)) {
            handleChange(name, null);
        } else {
            handleChange(name, value);
        }

        setModified(true);
    };

    const handleDelete = () => {
        let url = process.env.REACT_APP_API_URL + "/workareas/" + params._id;
        let config = {};

        if (token) {
            url += "?token=" + token;
        } else if (userContext.token) {
            config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };
        }

        axiosInstance.delete(url, config)
            .then(res => {
                if (token) {
                    navigate("/projects/" + details.projectId + "?token=" + token, { replace: true, state: { snackbarMessage: "Work deleted" } });
                } else {
                    navigate("/projects/" + details.projectId, { replace: true, state: { snackbarMessage: "Work deleted" } });
                }
            })
            .catch(err => {
                setErrorMessage(err.response.data);
                setShowErrorMessage(true);
                props.setSnackbarMessage("Unable to delete work");
                props.setOpenSnackbar(true);
            });
    };

    useEffect(() => {
        if (processedImage) {
            fetch(processedImage)
                .then(res => {
                    res.blob().then(imgBlob => {
                        const img = new File([imgBlob], "image", { type: imgBlob.type });

                        let updatedDetailsImages = details.images ? details.images : [];
                        updatedDetailsImages[selectedImageIndex] = img;
                        setDetails(details => ({ ...details, images: updatedDetailsImages }));

                        let updatedImages = images ? images : [];
                        updatedImages[selectedImageIndex] = processedImage;
                        setImages(updatedImages);

                        setProcessedImage();
                        setModified(true);
                        setImagesModified(true);
                    });
                });

            setOpenEditImageModal(false);
        }
    }, [processedImage, details.images, selectedImageIndex, images]);

    const getImage = useCallback(async image => {
        let url = process.env.REACT_APP_API_URL + "/images/" + image;
        let config = {};

        if (token) {
            url += "?token=" + token;
            config = { responseType: "arraybuffer" };
        } else if (userContext.token) {
            config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext, responseType: "arraybuffer" };
        }

        try {
            const fetchRes = await axiosInstance.get(url, config);
            const blob = new Blob([fetchRes.data], { type: fetchRes.headers.getContentType() });
            return URL.createObjectURL(blob);
        }
        catch (err) {
            // Do nothing
        }
    }, [token, setUserContext, userContext]);

    useEffect(() => {
        const fetchEntry = async () => {
            let url = process.env.REACT_APP_API_URL + "/workAreas/" + params._id;
            let config = {};

            if (token) {
                url += "?token=" + token;
            } else if (userContext.token) {
                config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };
            }

            try {
                const res = await axiosInstance.get(url, config);

                let fetchedDetails = {
                    ...res.data,
                    startDate: res.data.startDate ? dayjs(res.data.startDate) : null,
                    endDate: res.data.endDate ? dayjs(res.data.endDate) : null,
                    colors: res.data?.colors?.length > 0 ? res.data.colors : [{ name: "" }],
                    employees: res.data?.employees?.length > 0 ? res.data.employees : [{ name: "" }],
                    markers: res.data?.markers?.length > 0 ? res.data.markers : [],
                    number: (res.data.index + 1).toString()
                };

                if (res.data.images) {
                    let fetchedImages = [];
                    let fetchedDetailsImages = [];

                    for (const img of res.data.images) {
                        let url = process.env.REACT_APP_API_URL + "/images/" + img;
                        let config = {};

                        if (token) {
                            url += "?token=" + token;
                            config = { responseType: "arraybuffer" };
                        } else if (userContext.token) {
                            config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext, responseType: "arraybuffer" };
                        }

                        const fetchRes = await axiosInstance.get(url, config);
                        const blob = new Blob([fetchRes.data], { type: fetchRes.headers.getContentType() });
                        const imgFile = new File([blob], "image", { type: blob.type });
                        fetchedDetailsImages.push(imgFile);
                        fetchedImages.push(URL.createObjectURL(blob));
                    }

                    setImages(fetchedImages);
                    fetchedDetails.images = fetchedDetailsImages;
                }

                setDetails(details => ({ ...details, ...fetchedDetails }));
            } catch (err) {
                // Do nothing
            }
        };

        if (params._id) {
            fetchEntry();
        }
    }, [params._id, userContext, setUserContext, token, getImage]);

    useEffect(() => {
        const fetchBlueprint = async () => {
            let url = process.env.REACT_APP_API_URL + "/projects/" + details.projectId;
            let config = {};

            if (token) {
                url += "?token=" + token;
            } else if (userContext.token) {
                config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };
            }

            try {
                const res = await axiosInstance.get(url, config);
                setBlueprint(await getImage(res.data.blueprint));
            } catch (err) {
                // Do nothing
            }
        };

        if (details.projectId) {
            fetchBlueprint();
        }
    }, [details.projectId, token, userContext, setUserContext, getImage]);

    const handleSubmit = event => {
        event.preventDefault();
        setDisableSubmitButton(true);

        if (!imagesModified) {
            delete details.images;
        } else if (images && images.length === 0) {
            details.images = "";
        }

        if (details.markers && details.markers.length === 0) {
            details.markers = "";
        };

        if (details.employees && details.employees.length === 0) {
            details.employees = "";
        };

        if (details.colors && details.colors.length === 0) {
            details.colors = "";
        };

        let url = process.env.REACT_APP_API_URL + "/workAreas/" + params._id;
        const payload = { ...details };
        let config = {};

        if (payload.colors) {
            payload.colors = payload.colors.filter(color => color.name);
        }

        if (payload.employees) {
            payload.employees = payload.employees.filter(employee => employee.name);
        }

        if (payload.startDate === null) {
            payload.startDate = "";
        }

        if (payload.endDate === null) {
            payload.endDate = "";
        }

        payload.index = parseInt(payload.number) - 1;

        if (token) {
            url += "?token=" + token;
        } else if (userContext.token) {
            config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };
        }

        axiosInstance.postForm(url, payload, config)
            .then(res => {
                if (token) {
                    navigate("/projects/" + details.projectId + "?token=" + token, { replace: true, state: { snackbarMessage: "Work modified" } });
                } else {
                    navigate("/projects/" + details.projectId, { replace: true, state: { snackbarMessage: "Work modified" } });
                }
            })
            .catch(err => {
                setErrorMessage(err.response.data);
                setShowErrorMessage(true);
                setDisableSubmitButton(false);
            });
    };

    useEffect(() => setIsLoading(!details._id), [details]);

    const handleAddEmployee = () => {
        const updatedDetails = { ...details };
        updatedDetails.employees.push({ name: "" });
        setDetails(updatedDetails);
        setAddingEmployee(true);
        setModified(true);
    };

    const handleEmployeeChange = (value, index) => {
        const updatedDetails = { ...details };
        updatedDetails.employees[index].name = value;
        setDetails(updatedDetails);
        setModified(true);
    };

    const handleRemoveEmployee = (index) => {
        const updatedDetails = { ...details };
        updatedDetails.employees.splice(index, 1);
        setDetails(updatedDetails);
        setModified(true);
    };

    const handleAddColor = () => {
        const updatedDetails = { ...details };
        updatedDetails.colors.push({ name: "" });
        setDetails(updatedDetails);
        setAddingColor(true);
        setModified(true);
    };

    const handleColorChange = (value, index) => {
        const updatedDetails = { ...details };
        updatedDetails.colors[index].name = value;
        setDetails(updatedDetails);
        setModified(true);
    };

    const handleRemoveColor = (index) => {
        const updatedDetails = { ...details };
        updatedDetails.colors.splice(index, 1);
        setDetails(updatedDetails);
        setModified(true);
    };

    useEffect(() => {
        if (!topRef.current) return;
        topRef.current.scrollIntoView();
    }, [topRef]);

    useEffect(() => {
        if (lastColorFieldRef.current && addingColor) {
            setAddingColor(false);
            lastColorFieldRef.current.focus();
        }
    }, [addingColor]);

    useEffect(() => {
        if (lastEmployeeFieldRef.current && addingEmployee) {
            setAddingEmployee(false);
            lastEmployeeFieldRef.current.focus();
        }
    }, [addingEmployee]);

    useEffect(() => {
        const fetchOptions = () => {
            let url = process.env.REACT_APP_API_URL + "/workareas/options";
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            axiosInstance.get(url, config)
                .then(res => {
                    setOptions(options => ({ ...options, colors: res.data.colorOptions }));

                    let url = process.env.REACT_APP_TIME_APP_API_URL + "/users/options";
                    const config = { params: { token: userContext.token }, userContext: userContext, setUserContext: setUserContext };

                    axiosInstance.get(url, config)
                        .then(rslts => {
                            setOptions(options => ({ ...options, employees: rslts.data.userOptions }));
                        })
                        .catch(err => {
                            // Do nothing
                        });
                })
                .catch(err => {
                    // Do nothing
                });
        };

        fetchOptions();
    }, [userContext, setUserContext]);

    return (!isLoading &&
        <Grid container direction="column" sx={{ maxWidth: "1000px" }} ref={topRef}>
            <Grid container item sx={{ justifyContent: "center", paddingBottom: "25px" }}>
                <TopBar title="Edit Work" enableBack modified={false} backRoute={-1} link={link} />
            </Grid>
            <Grid container item sx={{ justifyContent: "center", paddingBottom: "35px" }}>
                <Typography color="black">What changes do you want to make?</Typography>
            </Grid>
            <Grid container item justifyContent="center">
                <form onSubmit={handleSubmit} style={{ width: "100%", maxWidth: "500px" }}>
                    <Grid container item spacing={4} sx={{ mb: 2.5 }}>
                        <Grid container item spacing={2.5} alignItems="center">
                            <Grid container item alignItems="center">
                                <Typography {...headingParams}>Dates</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container spacing={2.5}>
                            <Grid item {...gridItemParams}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Start date"
                                        value={details.startDate}
                                        onChange={value => handleDateChange("startDate", value)}
                                        sx={{ width: "100%" }}
                                        open={openStartDate}
                                        onClose={() => setOpenStartDate(false)}
                                        slotProps={pickerSlotProps(setOpenStartDate, CalendarIcon, "startDate", "MM/DD/YYYY")}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item {...gridItemParams}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="End date"
                                        value={details.endDate}
                                        onChange={value => handleDateChange("endDate", value)}
                                        sx={{ width: "100%" }}
                                        open={openEndDate}
                                        onClose={() => setOpenEndDate(false)}
                                        slotProps={pickerSlotProps(setOpenEndDate, CalendarIcon, "endDate", "MM/DD/YYYY")}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={2.5} alignItems="center">
                            <Grid container item alignItems="center">
                                <Typography {...headingParams}>Work</Typography>
                            </Grid>
                        </Grid>
                        <Grid container item>
                            <Autocomplete
                                {...autocompleteParams}
                                options={[]}
                                renderInput={params => (<TextField {...params} label="Work area / Part" value={details.name} inputProps={{ ...params.inputProps, autoCapitalize: "words" }} InputLabelProps={{ shrink: true, required: true }} helperText="Example: Transom Door" />)}
                                onInputChange={(event, value) => handleChange("name", value)}
                                inputValue={details.name}
                            />
                        </Grid>
                        <Grid container item>
                            <Autocomplete
                                {...autocompleteParams}
                                options={[]}
                                renderInput={params => (<TextField {...params} multiline rows={3} label="Work description" value={details.description} inputProps={{ ...params.inputProps, autoCapitalize: "sentences" }} InputLabelProps={{ shrink: true }} helperText="Example: Repair blister" />)}
                                onInputChange={(event, value) => handleChange("description", value)}
                                inputValue={details.description}
                                blurOnSelect={false}
                            />
                        </Grid>
                        <Grid container item ref={numberRef} alignItems="center">
                            <Grid container item xs={6}>
                                <Autocomplete
                                    {...autocompleteParams}
                                    options={numberOptions}
                                    renderInput={params => (<TextField {...params} label="Work #" value={details.number} InputLabelProps={{ shrink: true }} inputProps={{ ...params.inputProps, readOnly: true }} />)}
                                    onInputChange={(event, value) => value ? handleChange("number", value) : null}
                                    inputValue={details.number}
                                    disableClearable
                                    filterOptions={options => options}
                                    blurOnSelect={true}
                                    onOpen={() => {
                                        if (isTouchScreenDevice()) {
                                            setTimeout(() => {
                                                const popperHeight = Math.min(256, numberOptions.length * 48 + 16);
                                                numberRef.current.style.marginBottom = (popperHeight) + "px";
                                                numberRef.current.scrollIntoView();
                                            }, 100);
                                        }
                                    }}
                                    ListboxProps={{ sx: { maxHeight: "256px" } }}
                                    onClose={() => numberRef.current.style.marginBottom = "0"}
                                />
                            </Grid>
                            <Grid container item xs={6} justifyContent="flex-end" sx={{ pl: 2.5 }}>
                                <Typography sx={{ color: "rgba(32, 42, 68, 0.6)", fontSize: "14px" }}>Change the work numbering</Typography>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={2.5} alignItems="center">
                            <Grid container item alignItems="center">
                                <Typography {...headingParams}>Images</Typography>
                            </Grid>
                            <Grid container item direction="column" spacing={2.5}>
                                {images?.length > 0 &&
                                    <Grid container item>
                                        {images.map((image, imagesIndex) => {
                                            return (
                                                <Grid container item key={imagesIndex} spacing={2.5}>
                                                    <Grid item>
                                                        <Typography sx={{ color: "rgba(32, 42, 68, 0.6)", textAlign: "center", fontSize: "14px", mt: imagesIndex !== 0 ? 2.5 : 1.75 }}>
                                                            Image #{imagesIndex + 1}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid container item spacing={2.5}>
                                                        <Grid item xs={10}>
                                                            <img
                                                                src={image}
                                                                alt=""
                                                                width="100%"
                                                                style={{ objectFit: "cover", aspectRatio: 1, cursor: "pointer" }}
                                                                onClick={() => handleImageClick(imagesIndex)}
                                                            />
                                                        </Grid>
                                                        <Grid item container alignContent="center" xs={2}>
                                                            <IconButton tabIndex={-1} onClick={() => handleRemoveImage(imagesIndex)}><RemoveCircleIcon /></IconButton>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                }
                                <Grid container item spacing={2.5} sx={{ mt: 0.75 }}>
                                    <Grid container item xs={10}>
                                        <div style={{ width: "100%", borderRadius: "5px" }}>
                                            <label>
                                                <input hidden type="file" multiple accept="image/*" onChange={event => handleAddImages(event)} onClick={event => event.target.value = null} />
                                                <Grid container item sx={{ alignContent: "center", height: "300px", borderRadius: "5px", border: "2px dotted #495464", cursor: "pointer" }}>
                                                    <Grid container item justifyContent="center">
                                                        <Typography sx={{ color: "rgba(32, 42, 68, 0.6)", textAlign: "center", fontSize: "14px" }}>Click to add work area image(s)</Typography>
                                                    </Grid>
                                                    <Grid container item spacing={3.5} justifyContent="center" sx={{ mt: "10px" }}>
                                                        <Grid item>
                                                            <AddPhotoAlternateOutlinedIcon sx={{ width: 35, height: 35 }} />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </label>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={2.5} alignItems="center">
                            <Grid container item alignItems="center">
                                <Typography {...headingParams}>Colors</Typography>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={2.5}>
                            {details.colors?.length > 0 ?
                                details.colors.map((color, colorIndex) => {
                                    return (
                                        <Grid container item key={colorIndex} ref={selectedColorIndex === colorIndex ? colorRef : null}>
                                            <Grid container item flex={1} sx={{ minWidth: "200px", mr: 2.5 }}>
                                                <Autocomplete
                                                    {...autocompleteParams}
                                                    options={options.colors}
                                                    renderInput={params => (<TextField
                                                        {...params}
                                                        label={"Color #" + (colorIndex + 1)}
                                                        value={color.name}
                                                        inputProps={{ ...params.inputProps, autoCapitalize: "words" }}
                                                        InputLabelProps={{ shrink: true }}
                                                        helperText={color.name ? null : "Example: Awlgrip Snow White"}
                                                        inputRef={colorIndex === details.colors.length - 1 ? lastColorFieldRef : null}
                                                        onFocus={() => setSelectedColorIndex(colorIndex)}
                                                    />)}
                                                    onOpen={() => {
                                                        if (isTouchScreenDevice()) {
                                                            setTimeout(() => {
                                                                colorRef.current.style.marginBottom = "90vh";
                                                                colorRef.current.scrollIntoView();
                                                            }, 100);
                                                        }
                                                    }}
                                                    onClose={() => colorRef.current.style.marginBottom = "0"}
                                                    onInputChange={(event, value) => handleColorChange(value, colorIndex)}
                                                    inputValue={color.name}
                                                    openOnFocus
                                                />
                                            </Grid>
                                            <Grid container item alignItems="center" xs={2}>
                                                {colorIndex > 0 && <IconButton tabIndex={-1} onClick={() => handleRemoveColor(colorIndex)} sx={{ mb: color.name ? null : "23px" }}><RemoveCircleIcon /></IconButton>}
                                            </Grid>
                                        </Grid>
                                    )
                                })
                                :
                                <Grid item>
                                    <Typography sx={{ color: "rgba(32, 42, 68, 0.6)", textAlign: "center", fontSize: "14px" }}>No color added ;-(</Typography>
                                </Grid>
                            }
                            <Grid container item>
                                <Button
                                    onClick={handleAddColor}
                                    variant="outlined"
                                    sx={{ height: "45px", width: "150px", color: "#495464" }}
                                    component="span"
                                >
                                    <span style={{ marginLeft: "-4px", marginRight: "8px", display: "inherit" }}><AddIcon sx={{ fontSize: 20 }} /></span>
                                    Color
                                </Button>
                            </Grid>
                        </Grid>
                        {userContext.token &&
                            <>
                                <Grid container item spacing={2.5} alignItems="center">
                                    <Grid container item alignItems="center">
                                        <Typography {...headingParams}>Assigned To</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item spacing={2.5}>
                                    {details.employees?.length > 0 ?
                                        details.employees.map((employee, employeeIndex) => {
                                            return (
                                                <Grid container item key={employeeIndex} ref={selectedEmployeeIndex === employeeIndex ? employeeRef : null}>
                                                    <Grid container item flex={1} sx={{ minWidth: "200px", mr: 2.5 }}>
                                                        <Autocomplete
                                                            {...autocompleteParams}
                                                            options={options.employees}
                                                            renderInput={params => (<TextField
                                                                {...params}
                                                                label={"Employee #" + (employeeIndex + 1)}
                                                                value={employee.name}
                                                                inputProps={{ ...params.inputProps, autoCapitalize: "words" }}
                                                                InputLabelProps={{ shrink: true }}
                                                                helperText={employee.name ? null : "Example: Yonatan Cordova"}
                                                                inputRef={employeeIndex === details.employees.length - 1 ? lastEmployeeFieldRef : null}
                                                                onFocus={() => setSelectedEmployeeIndex(employeeIndex)}
                                                            />)}
                                                            onOpen={() => {
                                                                if (isTouchScreenDevice()) {
                                                                    setTimeout(() => {
                                                                        employeeRef.current.style.marginBottom = "90vh";
                                                                        employeeRef.current.scrollIntoView();
                                                                    }, 100);
                                                                }
                                                            }}
                                                            onClose={() => employeeRef.current.style.marginBottom = "0"}
                                                            onInputChange={(event, value) => handleEmployeeChange(value, employeeIndex)}
                                                            inputValue={employee.name}
                                                            openOnFocus
                                                        />
                                                    </Grid>
                                                    <Grid container item alignItems="center" xs={2}>
                                                        {employeeIndex > 0 && <IconButton tabIndex={-1} onClick={() => handleRemoveEmployee(employeeIndex)} sx={{ mb: employee.name ? null : "23px" }}><RemoveCircleIcon /></IconButton>}
                                                    </Grid>
                                                </Grid>
                                            )
                                        })
                                        :
                                        <Grid item>
                                            <Typography sx={{ color: "rgba(32, 42, 68, 0.6)", textAlign: "center", fontSize: "14px" }}>No employee assigned ;-(</Typography>
                                        </Grid>
                                    }
                                    <Grid container item alignItems="center">
                                        <Button
                                            onClick={handleAddEmployee}
                                            variant="outlined"
                                            sx={{ height: "45px", width: "150px", color: "#495464" }}
                                            component="span"
                                        >
                                            <span style={{ marginLeft: "-4px", marginRight: "8px", display: "inherit" }}><AddIcon sx={{ fontSize: 20 }} /></span>
                                            Employee
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                        }
                        <Grid container item spacing={2.5} alignItems="center">
                            <Grid container item alignItems="center">
                                <Typography {...headingParams}>Priority</Typography>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={2.5}>
                            <Grid item>
                                <Typography sx={{ color: "rgba(32, 42, 68, 0.6)", fontSize: "14px" }}>Select the work priority level:</Typography>
                            </Grid>
                            <Grid item>
                                <ToggleButtonGroup
                                    exclusive
                                    onChange={((event, selectedPriority) => handleChange("priority", selectedPriority))}
                                    value={details.priority}
                                    sx={{ "& .Mui-selected": { color: "rgba(0, 0, 0, 0.65)" } }}
                                    size="medium"
                                >
                                    <ToggleButton value="">None</ToggleButton>
                                    <ToggleButton value="Low"><CircleIcon sx={{ width: "10px", mr: "8px", color: "#8bacaa" }} />Low</ToggleButton>
                                    <ToggleButton value="Medium"><CircleIcon sx={{ width: "10px", mr: "8px", color: "#e38d5b" }} />Medium</ToggleButton>
                                    <ToggleButton value="High"><CircleIcon sx={{ width: "10px", mr: "8px", color: "#e76161" }} />High</ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={2.5} alignItems="center">
                            <Grid container item alignItems="center">
                                <Typography {...headingParams}>Billable</Typography>
                            </Grid>
                        </Grid>
                        <Grid container item direction="column" spacing={2.5}>
                            <Grid item>
                                <FormControlLabel checked={details.billable} control={<Checkbox />} onChange={event => handleChange("billable", !details.billable)} label="Work is billable T&M" />
                            </Grid>
                        </Grid>
                        {blueprint &&
                            <>
                                <Grid container item spacing={2.5} alignItems="center">
                                    <Grid container item alignItems="center">
                                        <Typography {...headingParams}>General Arrangement</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item direction="column" spacing={2.5}>
                                    <Grid item>
                                        <Typography sx={{ color: "rgba(32, 42, 68, 0.6)", fontSize: "14px" }}>Mark the area on the GA.</Typography>
                                    </Grid>
                                    <Grid item onLoad={() => setBlueprintWidth(blueprintRef.current.offsetWidth)} ref={blueprintRef}>
                                        <ImageMarker
                                            src={blueprint}
                                            markers={details.markers ? details.markers : []}
                                            onAddMarker={marker => {
                                                const updatedDetails = { ...details };
                                                details.markers.push(marker);
                                                setDetails(updatedDetails);
                                                setModified(true);
                                            }}
                                            markerComponent={() => <DotMarker imageWidth={blueprintWidth} />}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            onClick={() => {
                                                const updatedDetails = { ...details };
                                                updatedDetails.markers = updatedDetails.markers.slice(0, -1);
                                                setDetails(updatedDetails);
                                                setModified(true);
                                            }}
                                            variant="outlined"
                                            sx={{ height: "45px", width: "150px" }}
                                            component="span"
                                            disabled={details.markers?.length === 0}
                                        >
                                            <span style={{ marginLeft: "-4px", marginRight: "8px", display: "inherit" }}><UndoIcon sx={{ fontSize: 20 }} /></span>
                                            Undo
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                        }
                        <Grid container item>
                            <Grid container item spacing={2.5} alignItems="center">
                                <Grid container item alignItems="center">
                                    <Typography {...headingParams}>Delete Work</Typography>
                                </Grid>
                            </Grid>
                            <Grid container item sx={{ justifyContent: "space-between", alignItems: "center", mt: 2.5 }}>
                                <Grid item>
                                    <Typography>Would you like to delete this work?</Typography>
                                </Grid>
                                <Grid item>
                                    <IconButton onClick={() => setOpenDelete(true)}><DeleteOutlineIcon sx={{ fontSize: 30, color: "#495464" }} /></IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <br />
                    <ErrorBox showErrorMessage={showErrorMessage} errorMessage={errorMessage} />
                    <Button disabled={disableSubmitButton} type="submit" sx={{ width: "100%", marginTop: "20px", height: "45px", marginBottom: "35px" }}>Update</Button>
                </form >
            </Grid >
            {openDelete && <DeleteDialog open={openDelete} setOpen={setOpenDelete} handleDelete={handleDelete} type="work" />}
            {openEditImageModal &&
                <EditImageModal
                    openModal={openEditImageModal}
                    setOpenModal={setOpenEditImageModal}
                    image={image}
                    processedImage={processedImage}
                    setProcessedImage={setProcessedImage}
                />
            }
        </Grid >
    );
};

export default EditWork;